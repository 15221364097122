import React from 'react'
import { useEffect } from "react";
import axios from "axios";
import { useState, useRef } from "react";
import { Link } from 'react-router-dom'
import FavouritePhotosList from './FavouritePhotosList'
import { useSelector } from 'react-redux';
import { decryptUserData  } from '../../utils/encryptedData'





let increment = 0;

const FavouritePhotos = () => {


    let footer = useRef();
    let [firstloadPhoto, setfirstloadPhoto] = useState([])
    let [loadPhoto, setloadPhoto] = useState([])
    let [spinner, setspinner] = useState(false)
    let [CheckEmptyArry, setCheckEmptyArry] = useState(true)
    let [FilterValue, setFilterValue] = useState('')
    let isLogginState = useSelector(state => state.Login.isLoggedIn)



    // <---------------- decrypted data ------------->
    const encryptedUserData = localStorage.getItem('Data');
    const decryptedUserData = decryptUserData(encryptedUserData);
    let usertoken = decryptedUserData.token
    let isloggedin = decryptedUserData.isLoggedIn
    let Userid = decryptedUserData.userid
    let display_name = decryptedUserData.useDisplay_name
    let displaySocailUserPhoto = decryptedUserData.Display_UserSocailPhoto

    // <-------------------------------------------->




    function AddLibrary(urlOfTheLibrary) {
        const script = document.createElement("script");
        script.src = urlOfTheLibrary;
        script.async = true;
        document.body.appendChild(script);
    }

    let LeaderboardHandler = () => {
        setFilterValue("leader_board")
        increment = 0
        setCheckEmptyArry(true)
    }

    let MostRecentHandler = () => {
        setFilterValue("by_recent_photos")
        increment = 0
        setCheckEmptyArry(true)
    }

    let NewMembersHandler = () => {
        setFilterValue("by_recent_users")
        increment = 0
        setCheckEmptyArry(true)

    }


    // ..............................
    let apiCall = (e) => {
        if (e[0].isIntersecting) {
            increment = increment + 10;

            let LoadPhotos = async function (c) {

                setspinner(true)


                
                try {
                    const payload = {
                        "userType": "all",
                        "sortingType": FilterValue || "by_recent_photos",
                        "offSet": increment
                    };
                    let headers = {
                        'Authorization': `Bearer ${usertoken}`,
                        'Content-Type': 'application/json'
                    }

                    const url = isloggedin
                        ? `${process.env.REACT_APP_BASE_URL}/photo/list`
                        : `${process.env.REACT_APP_BASE_URL}/photo/list_photo_for_landing`;
                    
                    const response = await axios.post(url, payload, { headers });

                    const newPhotos = response.data.data;
                    setloadPhoto(prevPhotos => [...prevPhotos, ...newPhotos])

                    if (response.data.data.length == 0 || response.data.data.length < 10) {
                        setCheckEmptyArry(false)
                    }

                    setspinner(false)

                } catch (error) {
                    console.error('Error:', error);

                   
                }
            }
            LoadPhotos();
        }


    }




    ///////////

    useEffect(() => {

        const Observer = new IntersectionObserver(apiCall, {
            root: null,
            threshold: 0

        })

        if (footer.current) Observer.observe(footer.current)
        return () => {
            if (footer.current) Observer.unobserve(footer.current);
        };
    }, [FilterValue, firstloadPhoto, isLogginState])
    ////////////
    useEffect(() => {
        setfirstloadPhoto([])
        setloadPhoto([])

        let LoadPhotos = async function () {
           
            setspinner(true)
            try {
                const payload = {
                    "userType": "all",
                    "sortingType": FilterValue || "by_recent_photos",
                    "offSet": 0
                };
                let headers = {
                    'Authorization': `Bearer ${usertoken}`,
                    'Content-Type': 'application/json'
                }
                const url = isloggedin
                        ? `${process.env.REACT_APP_BASE_URL}/photo/list`
                        : `${process.env.REACT_APP_BASE_URL}/photo/list_photo_for_landing`;
                    
                    const response = await axios.post(url, payload, { headers });

                setfirstloadPhoto(response.data.data)
                setspinner(false)
                setCheckEmptyArry(true)
                increment = 0
               
            } catch (error) {
                console.error('Error:', error.response.data.message);
               
            }
           
        }
        LoadPhotos();
    }, [isLogginState, FilterValue])

    let AllloadPhoto;
    if (increment === 0) {
        AllloadPhoto = [...firstloadPhoto]
    } else {

        AllloadPhoto = [...firstloadPhoto, ...loadPhoto]

    }


    return (
        <>

            <div className="feed_content">
                <div className="feed_content_heading d-flex flex-column flex-md-row align-items-center justify-content-center justify-content-lg-between flex-wrap">

                    {/* //// */}
                    <h3 className="title">Upvote Your Favorite Photos</h3>

                    <ul className="feedFilter dropdown">
                        <li className="activated"></li>
                        <img src="./assets/img/icons/arrow-down.svg" />

                        <ul className="filter_lists">

                            <li className="active">
                                <Link onClick={MostRecentHandler}>Most Recent </Link>
                            </li>
                            <li >
                                <Link onClick={LeaderboardHandler}>By Upvotes</Link>
                            </li>
                            <li>
                                <Link onClick={NewMembersHandler}>New Members</Link>
                            </li>
                            {/* <li>
                                <Link >Last Month’s Winners</Link>
                            </li> */}
                        </ul>

                    </ul>

                    {/* /// */}
                </div>

                <div className="feed_items text-center text-md-start">

                    {AllloadPhoto.map((data) => (<FavouritePhotosList

                        img={data.photo_url}
                        postId={data.id}
                        key={data.id}
                        TotalVotes={data.total_votes}
                        voted={data.is_vote}
                        Name={data.name}
                        postedDate={data.photo_posted_date}
                        Userlocation={data.state}
                        UserPhoto={data.user_photo_url}
                        Userid={data.user_id}
                        usertoken={usertoken}
                        isLoggedIn={isloggedin}
                        loginUserid={Userid}
                        display_name={display_name}
                        displaySocailUserPhoto={displaySocailUserPhoto}
                    />))


                    }
                    {CheckEmptyArry && spinner && <div style={{ display: "flex", justifyContent: "center" }}>
                        <div className="spinner-border text-secondary" role="status" >
                            <span className="sr-only"></span>
                        </div>
                    </div>}



                    {CheckEmptyArry && firstloadPhoto.length > 0 && <div ref={footer}></div>}


                </div>
            </div>

            {AddLibrary("./assets/js/magnific-popup.min.js")}
            {AddLibrary("./assets/js/owl.carousel.min.js")}
            {AddLibrary("./assets/js/main.js")}
        </>
    )
}

export default FavouritePhotos
import axios from 'axios'
import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import { formatDate } from "../../utils/Formatteddate"
import { formatLikes } from "../../utils/FomattedLike"
import { decryptUserData } from '../../utils/encryptedData'
import { ShortAddress } from '../../utils/Address'
import { useSelector } from 'react-redux'
import { useDispatch } from "react-redux";
import { ModalAction } from "../../Store/Modal-Slice";
import useImageLoader from '../../Hooks/useImageLoader';
import MainImageSkeleton from '../Skeleton/MainImageSkeleton'



const OtherUserSinglePhoto = (props) => {
    let [commentValue, setcommentValue] = useState("")
    let [PlaceholderValue, setPlaceholderValue] = useState(false)
    let [upDownvote, setupDownvote] = useState(props.voted || false)
    let [votebtnactive, setvotebtnactive] = useState(props.voted || false)
    let [TotalVote, setTotalVote] = useState(props.TotalVotes)


    // formatDate function 
    const formattedDate = formatDate(props.postedDate);
    /// Like
    const formattedLikes = formatLikes(TotalVote);

    ///address
    let address = ShortAddress(props.Userlocation, 16);

    // ImageLoaded hook 
    const isImageLoaded = useImageLoader(props.img);


    let joinModalHeading = useSelector(state => state.Modal.PopupModalMessage.joinModalHeading)

    let dispatch = useDispatch()
    useEffect(() => {
        if (!props.voted && joinModalHeading == "to upvote") {
           
            document.querySelector(".likebtn").click()

            dispatch(ModalAction.setPopupModalMessage({
                "joinModalHeading": "",
                "popupHeading": "",
                "popupMessage": "",
                photoID: "",
                userID: "",
            }))
        }
    }, [])

    // <---------------- decrypted data ------------->
    const encryptedUserData = localStorage.getItem('Data');
    const decryptedUserData = decryptUserData(encryptedUserData);
    let usertoken = decryptedUserData.token
    let display_name = decryptedUserData.useDisplay_name
    let displayUserSocailPhoto = decryptedUserData.Display_UserSocailPhoto

    // <-------------------------------------------->

    let commentInputHandler = (e) => {
        setcommentValue(e.target.value)
    }

    let SubmitHandler = async (event) => {
        event.preventDefault();


        try {
            const payload = {
                "photoID": props.postId,
                "commentText": commentValue
            };
            let headers = {
                'Authorization': `Bearer ${usertoken}`,
                'Content-Type': 'application/json'
            }
            const response = await axios.post(`${process.env.REACT_APP_BASE_URL}/comment/add`, payload, { headers });



               } catch (error) {
            console.error('Error:', error.response.data.message);
               }

        setcommentValue('')
        setPlaceholderValue(true)

    }

    let voteHandler = async (event) => {

        event.preventDefault();
        setvotebtnactive(!votebtnactive)
        setupDownvote(!upDownvote)



        let up_or_down_vote;
        if (upDownvote) {
            up_or_down_vote = "remove"
        } else {
            up_or_down_vote = "add"

        }
        // ......................
        try {
            const payload = {
                "photoID": props.postId,
                "actionType": up_or_down_vote
            };
            let headers = {
                'Authorization': `Bearer ${usertoken}`,
                'Content-Type': 'application/json'
            }
            const response = await axios.post(`${process.env.REACT_APP_BASE_URL}/vote/perform`, payload, { headers });

            setTotalVote(response.data.data.voteCount)
            // setFirst5CommentsPhotoList(response.data.data.voteCount.commenters_photo_urls)





               } catch (error) {
            console.error('Error:', error);
               }

    }




    return (
        <>

            <div className="feed_item">
                <div className="feed_item_inner">
                    {!isImageLoaded ? (
                        <MainImageSkeleton />
                    ) : (
                        <img className='main-img img-fluid' src={props.img} alt="image" />
                    )}
                    <div className="feed_hover_text">
                        <div className="feed_user">
                            <a href="#"
                                className='user_img'
                                style={{
                                    backgroundImage: `url(${props.UserPhoto || ""})`,
                                    backgroundPosition: "center",
                                    backgroundSize: "cover",
                                    backgroundRepeat: "no-repeat",
                                    borderRadius: "50%",
                                    cursor: "pointer",
                                }}
                            >
                                {!props.UserPhoto && <img src={`https://ui-avatars.com/api/?name=${props.Name}&size=40&rounded=true&background=01655a&color=fff`} alt="" />}
                            </a>
                            <div className="user_text">
                                <a href="#" className="user_name">{props.Name}</a>
                                <p className="user_meta">
                                    {address && <span className="location">{address}</span>}
                                    <span className="date">{formattedDate}</span>
                                </p>
                            </div>
                        </div>
                        <div className="feed_vote">
                            <Link onClick={voteHandler} className={`btn likebtn btn-vote ${votebtnactive ? "active" : ""}`}>
                                <img src="./assets/img/icons/arrow-up.svg" />
                                {formattedLikes}
                            </Link>
                        </div>
                    </div>
                </div>
                <form onSubmit={SubmitHandler}>
                    <div className="feed_comment">
                        <div className="comment_user"
                            style={{
                                backgroundImage: `url(${displayUserSocailPhoto || ""})`,
                                backgroundPosition: "center",
                                backgroundSize: "cover",
                                backgroundRepeat: "no-repeat",
                                borderRadius: "50%",
                                cursor: "pointer",
                            }}
                        >
                            {!displayUserSocailPhoto && <img src={`https://ui-avatars.com/api/?name=${display_name}&size=40&rounded=true&background=01655a&color=fff`} />}
                        </div>
                        <div className="comment_field">
                            <input type="text" name="comment" placeholder={!PlaceholderValue ? "Add feedback and make a photographer smile." : "Thanks! We’ve sent your feedback to the photographer. Watch here for our improved comment section soon!"} className={`${commentValue ? "typed" : ''}`} value={commentValue} onChange={commentInputHandler} />
                            <button type="submit" className="comment-btn">
                                <img src="./assets/img/icons/subtract.svg" />
                            </button>
                        </div>
                    </div>
                </form>
            </div>

        </>
    )
}

export default OtherUserSinglePhoto
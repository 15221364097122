import axios from 'axios';
import React from 'react'
import { useState, useEffect } from 'react';
import { Link } from "react-router-dom";
import { useNavigate, Navigate } from "react-router-dom"
import { formatDate } from "../../utils/Formatteddate"
import { formatLikes } from "../../utils/FomattedLike"
import { decryptUserData } from '../../utils/encryptedData'
import { ShortAddress } from '../../utils/Address';
import useImageLoader from '../../Hooks/useImageLoader';
import MainImageSkeleton from '../Skeleton/MainImageSkeleton'

const OtherUserPhotoList = (props) => {

  let [upDownvote, setupDownvote] = useState(props.voted || false)
  let [votebtnactive, setvotebtnactive] = useState(props.voted || false)
  let [TotalVote, setTotalVote] = useState(props.TotalVotes)
  let [commentValue, setcommentValue] = useState("")
  let [PlaceholderValue, setPlaceholderValue] = useState(false)




  // <---------------- decrypted data ------------->
  const encryptedUserData = localStorage.getItem('Data');
  const decryptedUserData = decryptUserData(encryptedUserData);
  let usertoken = decryptedUserData.token
  let isLoggedIn = decryptedUserData.isLoggedIn
  let display_name = decryptedUserData.useDisplay_name
  let displayUserSocailPhoto = decryptedUserData.Display_UserSocailPhoto
  let userid = decryptedUserData.userid
  // <-------------------------------------------->

  // formatDate function 
  const formattedDate = formatDate(props.postedDate);


  //// Like

  const formattedLikes = formatLikes(TotalVote);

  ///address
  let address = ShortAddress(props.Userlocation, 16);

  // ImageLoaded hook 
  const isImageLoaded = useImageLoader(props.img);


  let navigate = useNavigate();
  let OtheruserProfileNavigateHandler = (event) => {
    event.preventDefault();



    if (userid == props.Userid) {
      navigate("/profile");
    } else {
      navigate(`/otherusers-profile?photoid=${props.postId}&userid=${props.Userid}`);
    }

    window.scrollTo(0, 0)
  }
  ////

  useEffect(() => {
    setupDownvote(props.voted || false);
    setvotebtnactive(props.voted || false);

  }, [props.voted]);


  let commentInputHandler = (e) => {
    setcommentValue(e.target.value)
  }




  let SubmitHandler = async (event) => {
    event.preventDefault();

    try {
      const payload = {
        "photoID": props.postId,
        "commentText": commentValue
      };
      let headers = {
        'Authorization': `Bearer ${usertoken}`,
        'Content-Type': 'application/json'
      }
      const response = await axios.post(`${process.env.REACT_APP_BASE_URL}/comment/add`, payload, { headers });




     } catch (error) {
      console.error('Error:', error.response.data.message);
     }

    setcommentValue('')
    setPlaceholderValue(true)

  }


  let voteHandler = async (event) => {

    event.preventDefault();
    setvotebtnactive(!votebtnactive)
    setupDownvote(!upDownvote)



    let up_or_down_vote;
    if (upDownvote) {
      up_or_down_vote = "remove"
    } else {
      up_or_down_vote = "add"

    }
    // ......................
    try {
      const payload = {
        "photoID": props.postId,
        "actionType": up_or_down_vote
      };
      let headers = {
        'Authorization': `Bearer ${usertoken}`,
        'Content-Type': 'application/json'
      }
      const response = await axios.post(`${process.env.REACT_APP_BASE_URL}/vote/perform`, payload, { headers });
      
      setTotalVote(response.data.data.voteCount)

    } catch (error) {
      console.error('Error:', error);
     }

  }
  return (
    <>
      <div className="feed_item">
        <div className="feed_item_inner">
          <Link to="" onClick={isLoggedIn ? OtheruserProfileNavigateHandler : () => { }} data-bs-toggle={`${!isLoggedIn ? "modal" : ""}`} data-bs-target="#joinModal">
            {!isImageLoaded ? (
              <MainImageSkeleton />
            ) : (
              <img className='main-img img-fluid' src={props.img} alt="image" />
            )}

          </Link>

          <div className="feed_hover_text">
            <div className="feed_user">
              <Link onClick={OtheruserProfileNavigateHandler}
                className='user_img'
                style={{
                  backgroundImage: `url(${props.UserPhoto || ""})`,
                  backgroundPosition: "center",
                  backgroundSize: "cover",
                  backgroundRepeat: "no-repeat",
                  borderRadius: "50%",
                  cursor: "pointer",
                }}
              >
                {!props.UserPhoto && <img src={`https://ui-avatars.com/api/?name=${props.Name}&size=40&rounded=true&background=01655a&color=fff`} alt="" />}
              </Link>
              <div className="user_text">
                <Link to="#" className="user_name" onClick={isLoggedIn ? OtheruserProfileNavigateHandler : () => { }} data-bs-toggle={`${!isLoggedIn ? "modal" : ""}`} data-bs-target="#joinModal">
                  {props.Name}
                </Link>
                <p className="user_meta">
                  {address && <span className="location">{address}</span>}
                  <span className="date">{formattedDate}</span>
                </p>
              </div>
            </div>

            <div className="feed_vote">
              <Link onClick={isLoggedIn ? voteHandler : () => { }} data-bs-toggle={`${!isLoggedIn ? "modal" : ""}`} data-bs-target="#joinModal" className={`btn btn-vote ${votebtnactive ? "active" : ""}`}>
                <img src="./assets/img/icons/arrow-up.svg" alt="" />
                {formattedLikes}
              </Link>
            </div>

          </div>

        </div>
        {isLoggedIn && <form onSubmit={SubmitHandler}>


          <div className="feed_comment">
            <div className="comment_user"
              style={{
                backgroundImage: `url(${displayUserSocailPhoto || ""})`,
                backgroundPosition: "center",
                backgroundSize: "cover",
                backgroundRepeat: "no-repeat",
                borderRadius: "50%",
                cursor: "pointer",
              }}
            >
              {!displayUserSocailPhoto && <img src={`https://ui-avatars.com/api/?name=${display_name}&size=40&rounded=true&background=01655a&color=fff`} />}
            </div>
            <div className="comment_field">
              <input type="text" name="comment" placeholder={!PlaceholderValue ? "Add feedback and make a photographer smile." : "Thanks! We’ve sent your feedback to the photographer. Watch here for our improved comment section soon!"} className={`${commentValue ? "typed" : ''}`} value={commentValue} onChange={commentInputHandler} />
              <button type="submit" className="comment-btn">
                <img src="./assets/img/icons/subtract.svg" />
              </button>
            </div>
          </div>

        </form>}
      </div>
    </>
  )
}

export default OtherUserPhotoList
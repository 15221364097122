import React from 'react'
import { calculateTimeDifference } from '../../utils/CalculateCommentTime';


const Commentslist = (props) => {
  



  const timeDifference = calculateTimeDifference(props.Ceatedat);
  return (
    <>

      <li className="comment" >
        <div className="comment_img"
          style={{
            backgroundImage: `url(${props.userPhoto || ""})`,
            backgroundPosition: "center",
            backgroundSize: "cover",
            backgroundRepeat: "no-repeat",
            borderRadius: "50%",
            cursor: "pointer",
            
          }}
        >
          {!props.userPhoto && <img src={`https://ui-avatars.com/api/?name=${props.username}&size=40&rounded=true&background=01655a&color=fff`} alt="" />}
        </div>
        <div className="comment_text">
          <h6 className="name">{props.username}</h6>
          <span className="date">{timeDifference}</span>
          <p className="text">
            {props.commentText}
          </p>
        </div>
      </li>
    </>
  )
}

export default Commentslist
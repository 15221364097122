import React, { useState } from 'react'
import TopTenList from '../Components/Top10/TopTenList';
import Members from '../Components/Feed/Members'
import { useEffect } from 'react';
import { decryptUserData } from '../utils/encryptedData'
import axios from 'axios';


const TopTenPage = () => {

    let [Top10Photo, setTop10Photo] = useState([])
    let [currentdate, setcurrentdate] = useState('')



    function AddLibrary(urlOfTheLibrary) {
        const script = document.createElement("script");
        script.src = urlOfTheLibrary;
        script.async = true;
        document.body.appendChild(script);
    }

    // <---------------- decrypted data ------------->
    const encryptedUserData = localStorage.getItem('Data');
    const decryptedUserData = decryptUserData(encryptedUserData);
    let usertoken = decryptedUserData.token
    let isloggedin = decryptedUserData.isLoggedIn
    let Userid = decryptedUserData.userid
    let display_name = decryptedUserData.useDisplay_name
    let displaySocailUserPhoto = decryptedUserData.Display_UserSocailPhoto

    // <-------------------------------------------->

    useEffect(() => {
        const fetchData = async () => {
            try {
                
                let payload = {
                        "userType": isloggedin ? "by_user" : "all",
                        "sortingType": "upvoted_photo",
                    };
                    
                const headers = {
                    Authorization: `Bearer ${usertoken}`,
                    'Content-Type': 'application/json',
                };

                const url = isloggedin
                        ? `${process.env.REACT_APP_BASE_URL}/photo/weekly_upvoted_photos`
                        : `${process.env.REACT_APP_BASE_URL}/photo/weekly_upvoted_photos`;
                    
                    const response = await axios.post(url, payload, { headers });
                
                
                setTop10Photo(response.data.data[0].most_upvoted_photos)
                setcurrentdate(response.data.data[0].start_date)
                // //changing date format
                // dateString = Top10Photo[0].start_date;
            } catch (error) {
                console.error(error);
            }
        };

        fetchData();
    }, [])



    return (
        <>
            <main className="site-content" id="main">
                {/* start: Page Heading */}
                <div className="page-header">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-8">
                                <div className="content_heading">
                                    <h3 className="title">Weekly Top 10</h3>
                                    <p className="desc">Each week we celebrate our community's top ten most upvoted photos in our exclusive email and our social media.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* end: Page Heading */}

                {/* start: Photo Feed */}
                <section className="photo-feed-section weekly-top">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-8 col-lg-8">
                                <div className="feed_content">
                                    <div className="feed_content_heading d-flex gap-lg-3 flex-column flex-md-row align-items-center justify-content-center justify-content-lg-between flex-wrap">
                                        <h3 className="title">Last Week's Most Upvoted Photos</h3>
                                        <ul className="feedFilter dropdown">
                                            <li className="activated" />
                                            <img src="./assets/img/icons/arrow-down.svg" alt />
                                            <ul className="filter_lists">
                                                <li className="active">
                                                    <a href=''>Week of {currentdate}</a>
                                                </li>
                                                {/* <li><a href="javascript:void(0)">Most Recent </a></li>
                                                <li><a href="javascript:void(0)">New Members</a></li>
                                                <li>
                                                    <a href="javascript:void(0)">Last Month’s Winners</a>
                                                </li> */}
                                            </ul>
                                        </ul>
                                    </div>
                                    <div className="feed_items text-center text-md-start">

                                        {Top10Photo && Top10Photo.map((data) => (<TopTenList

                                            img={data.photo_url}
                                            postId={data.id}
                                            key={data.id}
                                            TotalVotes={data.total_votes}
                                            voted={data.is_vote}
                                            Name={data.name}
                                            postedDate={data.photo_posted_date}
                                            Userlocation={data.state}
                                            UserPhoto={data.user_photo_url}
                                            Userid={data.user_id}
                                            usertoken={usertoken}
                                            isLoggedIn={isloggedin}
                                            loginUserid={Userid}
                                            display_name={display_name}
                                            displaySocailUserPhoto={displaySocailUserPhoto}
                                        />))


                                        }

                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-1 d-none d-lg-block">
                                <div className="line" />
                            </div>
                            <div className="col-md-4 col-lg-3 d-none d-md-block">
                                <Members />
                            </div>
                        </div>
                    </div>
                </section>
            </main>
            {/* end: Photo Feed */}
            {AddLibrary("./assets/js/magnific-popup.min.js")}
            {AddLibrary("./assets/js/owl.carousel.min.js")}
            {AddLibrary("./assets/js/main.js")}

        </>
    )
}

export default TopTenPage
import React from 'react'
import { Link } from 'react-router-dom'
import CommentsListContainer from '../Comments/CommentsListContainer'
import { useState } from 'react'
import axios from 'axios'
import { useNavigate } from "react-router-dom"
import { useDispatch } from "react-redux";
import { ModalAction } from "../../Store/Modal-Slice";
import { formatDate } from "../../utils/Formatteddate"
import { formatLikes } from "../../utils/FomattedLike"
import CryptoJS from 'crypto-js'
import { decryptUserData } from '../../utils/encryptedData'
import { ShortAddress } from '../../utils/Address'
import { calculateTimeDifference } from '../../utils/CalculateCommentTime'
import useImageLoader from '../../Hooks/useImageLoader';
import MainImageSkeleton from '../Skeleton/MainImageSkeleton'



const ProfileMyPhotoFeedList = (props) => {
    let [showlist, setlist] = useState(false)
    let [showlist2, setlist2] = useState(true)
    let [First3Comments, setFirst3Comments] = useState([])
    let [TotalVote, setTotalVote] = useState(props.TotalVotes)
    let [upDownvote, setupDownvote] = useState(props.voted || false)
    let [votebtnactive, setvotebtnactive] = useState(props.voted || false)



    

    // formatDate function 
    const formattedDate = formatDate(props.postedDate);

    /// Like
    const formattedLikes = formatLikes(TotalVote);

    ///address
    let address = ShortAddress(props.Userlocation, 16);
    
    // ImageLoaded hook 
    const isImageLoaded = useImageLoader(props.img);



    let dispatch = useDispatch()
    let navigate = useNavigate();


    // <---------------- decrypted data ------------->
    const encryptedUserData = localStorage.getItem('Data');
    const decryptedUserData = decryptUserData(encryptedUserData);
    let usertoken = decryptedUserData.token
    let displayUserSocailPhoto = decryptedUserData.Display_UserSocailPhoto
    // <-------------------------------------------->


    // let singlePhotoHandler = (event) => {
    //     event.preventDefault();
    //     let secretKey = "siddiqui"
    //     const encryptedID = CryptoJS.AES.encrypt(props.postId, secretKey).toString();
    //     navigate(`/Photo?id=${encryptedID}&Source=other`);
    // }

    let SharePhotobtnHandler = () => {
        dispatch(ModalAction.setSharePhotoModalState(true)) 
        
        dispatch(ModalAction.setShareModalPhotoInfo(
            {
                "path": props.img,
                "id": props.postId
            }
        ))

        // dispatch(ModalAction.setSharePhotoModalState(true))

    }





    ////

    let commentlistloadHandler = () => {
        setlist(!showlist)
        setlist2(!showlist2)
    }

    let commentlisthideHandler = () => {
        setlist(!showlist)
        setlist2(!showlist2)
    }



    let voteHandler = async (event) => {

        event.preventDefault();
        setvotebtnactive(!votebtnactive)
        setupDownvote(!upDownvote)



        let up_or_down_vote;
        if (upDownvote) {
            up_or_down_vote = "remove"
        } else {
            up_or_down_vote = "add"

        }
        // ......................
        try {
            const payload = {
                "photoID": props.postId,
                "actionType": up_or_down_vote
            };
            let headers = {
                'Authorization': `Bearer ${usertoken}`,
                'Content-Type': 'application/json'
            }
            const response = await axios.post(`${process.env.REACT_APP_BASE_URL}/vote/perform`, payload, { headers });

            setTotalVote(response.data.data.voteCount)
            // setFirst5CommentsPhotoList(response.data.data.voteCount.commenters_photo_urls)





               } catch (error) {
            console.error('Error:', error);
               }

    }
    let CommentarrayLength;
    if (props.First3Comments) {

        CommentarrayLength = props.First3Comments.length
    }


    return (
        <>

            <div className="feed_item">
                <div className="feed_item_inner">
                    <Link
                    // to="/singlePhoto"
                    // onClick={singlePhotoHandler}
                    >
                        {!isImageLoaded ? (
                            <MainImageSkeleton />
                        ) : (
                            <img className='main-img img-fluid' src={props.img} alt="image" />
                        )}
                       
                    </Link>
                    <div className="feed_hover_text">
                        <div className="feed_user">
                            <a href='#'
                                className='user_img'
                                style={{
                                    backgroundImage: `url(${displayUserSocailPhoto || ""})`,
                                    backgroundPosition: "center",
                                    backgroundSize: "cover",
                                    backgroundRepeat: "no-repeat",
                                    borderRadius: "50%",
                                    cursor: "pointer",
                                }}
                            >
                                {!displayUserSocailPhoto && <img src={`https://ui-avatars.com/api/?name=${props.Name}&size=40&rounded=true&background=01655a&color=fff`} />}
                            </a>

                            <div className="user_text">
                                <a href='#' className="user_name">
                                    {props.Name}
                                </a>
                                <p className="user_meta">
                                    {address && <span className="location">{address}</span>}
                                    <span className="date">{formattedDate}</span>
                                </p>
                            </div>
                        </div>

                        <div className="feed_vote">
                            <Link onClick={voteHandler} className={`btn btn-vote ${votebtnactive ? "active" : ""}`}>
                                <img src="./assets/img/icons/arrow-up.svg" />
                                {formattedLikes}
                            </Link>
                        </div>
                    </div>
                </div>
                {/* <!-- Desk share button --> */}
                <div className="share_btn d-none d-md-block" style={{ float: "right", marginTop: "10px", zIndex: "1", position: "relative" }}>
                    <Link  className="btn"
                        
                        onClick={SharePhotobtnHandler}>
                        Share for more votes!
                    </Link>
                </div>
                <div className="comment_lists" style={{ display: 'block' }}>
                    <ul>
                        {props.First3Comments && props.First3Comments.map((data) => {

                            const timeDifference = calculateTimeDifference(data.createdAt);

                            return (
                                <li className="comment" key={data.id}>
                                    <div
                                        className="comment_img"
                                        style={{
                                            backgroundImage: `url(${data.user_profile_pic || ""})`,
                                            backgroundPosition: "center",
                                            backgroundSize: "cover",
                                            backgroundRepeat: "no-repeat",
                                            borderRadius: "50%",
                                            cursor: "pointer",
                                        }}
                                    >
                                        {!data.user_profile_pic && (
                                            <img
                                                src={`https://ui-avatars.com/api/?name=${data.username}&size=40&rounded=true&background=01655a&color=fff`}
                                                alt=""
                                            />
                                        )}
                                    </div>
                                    <div className="comment_text">
                                        <h6 className="name">{data.username}</h6>
                                        <span className="date">{timeDifference}</span>
                                        <p className="text">{data.comment_text}</p>
                                    </div>
                                </li>
                            );
                        })}

                    </ul>
                </div>
                <div className="feed_comment">
                    <div className="following_users">
                        {showlist2 && (props.TotalComments !== "0") && <ul className="users" onClick={commentlistloadHandler}>

                            {props.First5CommentsPhotoList && props.First5CommentsPhotoList.map((url, index) => (
                                <li key={index} >
                                    <Link to=""
                                        style={{
                                            backgroundImage: `url(${url.photo_url
                                                || ""})`,
                                            backgroundPosition: "center",
                                            backgroundSize: "cover",
                                            backgroundRepeat: "no-repeat",
                                            borderRadius: "50%",
                                            cursor: "pointer",
                                        }}
                                    >
                                        {!url.photo_url && <img style={{ borderRadius: '50%', height: '100%', width: '100%' }}
                                            src={`https://ui-avatars.com/api/?name=${url.name}&size=40&rounded=true&background=01655a&color=fff`} alt="ddd" />}
                                    </Link>
                                </li>
                            ))}

                        </ul>}
                        {showlist2 && (props.TotalComments !== "0") && <Link

                            className="count"
                            id="viewComment"
                            onClick={commentlistloadHandler}
                        >
                            View more {Number(props.TotalComments)} feedbacks
                        </Link>}
                        {showlist && <Link
                            //  
                            // className="hide_comment"
                            id="hideComment"
                            onClick={commentlisthideHandler}
                            style={{ color: 'black', fontWeight: "600" }}
                        >
                            Hide Comments
                        </Link>}
                        {CommentarrayLength == "0" && <span className="count">0 Comments</span>}
                    </div>

                    {/* <div className="share_btn d-none d-md-block">
                        <Link to="#" className="btn"
                            data-bs-toggle="modal"
                            data-bs-target="#sharePhotoModal"
                            onClick={SharePhotobtnHandler}>
                            Share for more votes!
                        </Link>
                    </div> */}


                </div>
                {/* <!-- comment list --> */}

                {showlist && <CommentsListContainer postid={props.postId} />}



                {/* <!-- mobile share button --> */}
                <div className="share_btn d-md-none">
                    <Link  className="btn"  onClick={SharePhotobtnHandler}>
                        Share for more votes!
                    </Link>
                </div>
            </div>
        </>
    )
}

export default ProfileMyPhotoFeedList
import React from 'react'
import MembersList from './MembersList'
import axios from 'axios'
import { useEffect, useState, useRef } from 'react'
import { useSelector } from 'react-redux';
import { decryptUserData } from '../../utils/encryptedData'


let increment = 0;
const Members = () => {

  let [firstloadMembers, setfirstloadMembers] = useState([])
  let [loadMembers, setloadMembers] = useState([])
  let [CheckEmptyArry, setCheckEmptyArry] = useState(true)
  let [spinner, setspinner] = useState(false)

  // <---------------- decrypted data ------------->
  const encryptedUserData = localStorage.getItem('Data');
  const decryptedUserData = decryptUserData(encryptedUserData);
  let usertoken = decryptedUserData.token
  let isloggedin = decryptedUserData.isLoggedIn
  // <-------------------------------------------->


  let footer = useRef();
  let isLogginState = useSelector(state => state.Login.isLoggedIn)
  // ..............................
  let apiCall = (e) => {
    if (e[0].isIntersecting) {
      increment = increment + 10;
     
      let LoadMembers = async function () {


        
        setspinner(true)
        // ......................
        try {
          const payloadLogout = {
            "userType": "all",
            "offSet": increment
          };
          const payloadLogin = {
            "userType": "by_user",
            "offSet": increment
          };
          let headers = {
            'Authorization': `Bearer ${usertoken}`,
            'Content-Type': 'application/json'
          }
          let response;
          if (isloggedin) {
            response = await axios.post(`${process.env.REACT_APP_BASE_URL}/photo/list_photo_for_new_members`, payloadLogin, { headers });

          } else {
            response = await axios.post(`${process.env.REACT_APP_BASE_URL}/photo/list_photo_for_new_members`, payloadLogout);
          
          }
          
          
          const newMembers = response.data.data;
          setloadMembers(prevMembers => [...prevMembers, ...newMembers])

          if (response.data.data.length == 0 || response.data.data.length < 10 ) {
            setCheckEmptyArry(false)
          }
          setspinner(false)

        } catch (error) {
          console.error('Error:', error);
          setspinner(false)

          
        }
      }
      LoadMembers();
    }


  }


  

  ///////////

  useEffect(() => {
    const Observer = new IntersectionObserver(apiCall, {
      root: null,
      threshold: 0

    })
    
    if (footer.current) Observer.observe(footer.current)

  }, [firstloadMembers])
  ////////////
  useEffect(() => {

    let LoadMembers = async function () {


      increment=0
      setspinner(true)
      
      try {
        const payloadLogout = {
          "userType": "all",
          "offSet": 0
        };
        const payloadLogin = {
          "userType": "by_user",
          "offSet": 0
        };
        let headers = {
          'Authorization': `Bearer ${usertoken}`,
          'Content-Type': 'application/json'
        }
        let response;
        if (isloggedin) {
          response = await axios.post(`${process.env.REACT_APP_BASE_URL}/photo/list_photo_for_new_members`, payloadLogin, { headers });
          

        } else {
          response = await axios.post(`${process.env.REACT_APP_BASE_URL}/photo/list_photo_for_new_members`, payloadLogout);
          
        }

        setfirstloadMembers(response.data.data);
        setspinner(false)

      } catch (error) {
        console.error('Error:', error.response.data.message);
        setspinner(false)
         }
      // .............
    }
    LoadMembers();
  }, [isLogginState])

  let AllLoadMembers;
  if (increment === 0) {
    AllLoadMembers = [...firstloadMembers]
  } else {

    AllLoadMembers = [...firstloadMembers, ...loadMembers]

  }


  return (
    <>
      <div className="new_member_sidebar">
        <div className="member_sidebar_content">
          <div className="sidebar_heading">
            <h3 className="title">New Members</h3>
          </div>

          <div className="member_items">


            {AllLoadMembers.map((data) => (<MembersList

              img={data.photo_url}
              ProfilePic={data.profile_pic}
              photoId={data.photo_id}
              userId={data.id}
              key={data.id}
              TotalVotes={data.total_votes}
              voted={data.is_vote}
              Name={data.name}
              postedDate={data.photo_updated_at}
              userPhoto={data.profile_pic}
              userLocation={data.state}

            />))
            }
            {CheckEmptyArry && spinner && <div style={{ display: "flex", justifyContent: "center" }}>
              <div className="spinner-border text-secondary" role="status" >
                <span className="sr-only"></span>
              </div>
            </div>}



          </div>
        </div>
      </div>
      {CheckEmptyArry && firstloadMembers.length > 0 && <div ref={footer}></div>}
    </>
  )
}

export default Members
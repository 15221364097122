import React from 'react'
import OtherUserProfileInfo from '../Components/OtherUserProfile/OtherUserProfileInfo';
import OtherUserPhotoFeed from '../Components/OtherUserProfile/OtherUserPhotoFeed'
import { useEffect } from 'react';
import ReactGA from "react-ga"


const OtherUsersProfilePage = () => {

  useEffect(()=>{
    ReactGA.pageview(window.location.pathname + window.location.search);
  },[])
  
  return (
   <>
   <OtherUserProfileInfo/>
   <OtherUserPhotoFeed/>
   </>
  )
}

export default OtherUsersProfilePage
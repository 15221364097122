import React from 'react'
import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'


const ProfileInfoSkeleton = () => {
    return (
        <>

        
            <section className="profile-section">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-6">
                           
                                <div className="profile_info_inner">
                                    <div className="profile_img">
                                        <Skeleton circle height="100%" width="100%" />
                                    </div>
                                    <div className="profile_info_text">
                                        <div className="profile_name">
                                            <h4 className="name">
                                                <Skeleton width="70%" />
                                                <Skeleton />
                                            </h4>
                                        </div>

                                        <div className="connected_with d-none d-md-flex">
                                            <div className="following" style={{ display: 'flex', alignItems: 'center' }}>
                                                <Skeleton circle height={40} width={40} />
                                                <Skeleton circle height={40} width={40} />
                                                <Skeleton circle height={40} width={40} />
                                            </div>
                                            <div className="followers" style={{ display: 'flex', alignItems: 'center' }}>
                                                <Skeleton circle height={40} width={40} />
                                                <Skeleton circle height={40} width={40} />
                                                <Skeleton circle height={40} width={40} />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                          
                        </div>
                        <div className="col-lg-6">
                            <div className="profile_details">
                                <div className="profile_details_inner">
                                    <div className="details">
                                      
                                        <div className="details_item since">
                                        <Skeleton width={70} height={16}/>
                                        <Skeleton width={100} height={16}/>
                                        </div>

                                        <div className="details_item since">
                                        <Skeleton width={70} height={16}/>
                                        <Skeleton width={100} height={16}/>
                                        </div>
                                    </div>
                                    <div className="details">
                                       
                                        <div className="details_item genres">
                                        <Skeleton width={70} height={16}/>
                                        <Skeleton width={100} height={16}/>
                                        </div>
                                    </div>
                                   
                                </div>
                               
                                <div className="connected_with d-md-none">
                                <div className="following" style={{ display: 'flex', alignItems: 'center' }}>
                                                <Skeleton circle height={20} width={20} />
                                                <Skeleton circle height={20} width={20} />
                                                <Skeleton circle height={20} width={20} />
                                            </div>
                                    <div className="followers">
                                      
                                        <div className="following" style={{ display: 'flex', alignItems: 'center' }}>
                                                <Skeleton circle height={20} width={20} />
                                                <Skeleton circle height={20} width={20} />
                                                <Skeleton circle height={20} width={20} />
                                            </div>
                                    </div>
                                </div>
                                
                            </div>
                        </div>
                    </div>
                </div>
            </section>


        </>
    )
}

export default ProfileInfoSkeleton
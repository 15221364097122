import React from 'react'
import Cropper from 'react-easy-crop';
import getCroppedImg from '../../utils/CropImage';
import { Slider } from '@mui/material';
import axios from 'axios';
import { useEffect, useState, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ModalAction } from '../../Store/Modal-Slice';
import { decryptUserData } from '../../utils/encryptedData'
import { AES } from 'crypto-js';
import Swal from 'sweetalert2';


const ProfileImgUploadModal = () => {
  let [image, setimage] = useState('')
  let [imagePath, setimagePath] = useState('')
  let [CroppimagePath, setCroppimagePath] = useState('')
  let [ERROR, setERROR] = useState('')
  let [spinner, setspinner] = useState(false)
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(1);
  const [rotation, setRotation] = useState(0);
  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);

  let dispatch = useDispatch()
  const currentUrl = window.location.pathname;
  // <---------------- decrypted data ------------->
  const encryptedUserData = localStorage.getItem('Data');
  const decryptedUserData = decryptUserData(encryptedUserData);
  let usertoken = decryptedUserData.token


  // <-------------------------------------------->
  let MeApiState = useSelector(state => state.Modal.MeApiState)
  // <-------------------------------------------------------->
  
  const cropComplete = (croppedArea, croppedAreaPixels) => {
    setCroppedAreaPixels(croppedAreaPixels);
  };
  const cropImage = async () => {
    // setLoading(true);
    document.querySelector('.profile-img-modal-close_btn').click()
    try {
      const { file, url } = await getCroppedImg(
        imagePath,
        croppedAreaPixels,
        // rotation
      );
      setCroppimagePath(url)
      setimage(file);
      dispatch(ModalAction.setCropImg({
        cropImgPath: url,
        cropImg: file
      }))
      setimagePath('')
    } catch (error) {

      console.log(error);
    }
  };


  let cropandUploadHandler = async () => {
    setspinner(true)
    try {
      const timestamp = new Date().getTime();

      const { file, url } = await getCroppedImg(
        imagePath,
        croppedAreaPixels,
        // rotation
      );
      setCroppimagePath(url)
      setimage(file);
      let data = new FormData();
      data.append('page', "upload_photo");
      data.append('photoUrl', file, `profile_image_${timestamp}.jpeg`);
      let headers = {
        'Authorization': `Bearer ${usertoken}`
      };
      const response = await axios.post(`${process.env.REACT_APP_BASE_URL}/user/edit_profile`, data, { headers });

      dispatch(ModalAction.setMeApiState(!MeApiState))
      const secretKey = 'siddiqui';
      decryptedUserData.Display_UserSocailPhoto = response.data.data.photo_url

      let updatedEncryptedUserData = AES.encrypt(JSON.stringify(decryptedUserData), secretKey).toString();
      localStorage.setItem('Data', updatedEncryptedUserData);
      Swal.fire(
        '',
        'Upload successfully!',
        'success'
      )
      
      setimagePath('')
      setspinner(false)
      document.querySelector('.profile-img-modal-close_btn').click()
    } catch (error) {

      console.log(error);
      setspinner(false)
    }
  };

  // <-------------------------------------------------------->



  const MAX_IMAGE_EDGE = 5000; 
  const MAX_FILE_SIZE = 200 * 1024 * 1024; 
  const ALLOWED_FORMATS = ['image/jpeg', 'image/png'];



  let imgHandler = (e) => {
    setERROR("")
    let selectedImage = e.target.files[0]

    if (!ALLOWED_FORMATS.includes(selectedImage.type)) {
      setERROR('Please upload only JPG or PNG files.');
      return;
    }

   
    const img = new Image();
    img.src = URL.createObjectURL(selectedImage);
    img.onload = async () => {
      await img.decode(); 

      const { width, height } = img;


      if (width > MAX_IMAGE_EDGE || height > MAX_IMAGE_EDGE) {
        setERROR('Please ensure the edges of your photo are less than 5000 pixels.');
        return;
      } else if (selectedImage.size > MAX_FILE_SIZE) {
        setERROR('Please ensure that your file is less than 200MB.');
        return;
      } else {

        // setimage(selectedImage)

        const reader = new FileReader();
        reader.onload = () => {
          setimagePath(reader.result);
        };
        reader.readAsDataURL(selectedImage);
      }
    }


  }
  // <-------------------------------------------->
  return (
    <>
     <div className="backdrop" />
      {/* Upload Profile */}
      <div className="modal fade bph-modal show" id="profileUploadModal" tabIndex={-1} aria-labelledby="profileUploadModal" aria-hidden="true"
      style={{ display: 'block' }}>
        <div className="modal-dialog modal-dialog-centered modalAnimation">
          <div className="modal-content">
            <div className="modal-body">
              <div className="onboarding_modal photoUpload_modal profileUpload">
                <div className="modal_inner">
                  <button type="button" className="btn-close profile-img-modal-close_btn" data-bs-dismiss="modal" aria-label="Close" 
                  onClick={() => { dispatch(ModalAction.setProfileUploadModalState(false)) }}/>
                  {/* logo */}
                  <div className="modal_logo d-none d-md-inline-flex">
                    <img src="./assets/img/logo/modal-logo.png" alt />
                  </div>
                  {/* content */}
                  <div className="modal_content">
                    <div className="modal_title">
                      <h2 className="title"><img src="./assets/img/icons/upload.svg" alt />Upload a photo of you</h2>
                    </div>
                    {/* upload photo */}
                    <div className="file-upload">
                      {!imagePath && <p className="subtitle profile-upload-before-title">Add a photo to your profile so we can show the other
                        Big Photo
                        Hunters your
                        beautiful face! </p>}
                      {imagePath && <p className="subtitle profile-upload-after-title" style={{ display: 'block' }}>Position yourself in the circle</p>}
                      {/* <form action method="POST" encType="multipart/form-data"> */}

                      {ERROR && <p className="eerror" style={{ textAlign: 'center' }}>{ERROR} </p>}

                      {!imagePath && <div className="profile-image-upload-wrap">
                        <input className="profile-upload-input" type="file" onChange={imgHandler} />
                        <div className="drag-text">
                          <h4 className="title">Drag and drop your photo here</h4>
                          <span className="or">or</span>
                          <button className="file-upload-btn btn" type="button" 
                          // onclick="$('.profile-upload-input').trigger( 'click' )"
                          >Upload from device</button>
                          <span className="support_note">Max. 5000 x 5000 px., 200 megapixel, JPG or PNG format only.</span>
                        </div>
                      </div>}
                      {imagePath &&
                        <div>

                          <Cropper
                            image={imagePath}
                            crop={crop}
                            zoom={zoom}
                            rotation={rotation}
                            aspect={1}
                            // cropShape="round"
                            onZoomChange={setZoom}
                            onRotationChange={setRotation}
                            onCropChange={setCrop}
                            onCropComplete={cropComplete}
                            style={{  // Apply inline styling for a circular appearance
                              borderRadius: "50%",
                              overflow: "hidden",
                              width: "300px",  // Adjust the width and height to your preference
                              height: "300px",
                            }}
                          >
                          </Cropper>
                          <div className='slider_container'>
                            <Slider
                              // valueLabelDisplay="auto"
                              // valueLabelFormat={zoomPercent}
                              min={1}
                              max={3}
                              step={0.1}
                              value={zoom}
                              onChange={(e, zoom) => setZoom(zoom)}
                            />
                          </div>
                          <div className="profile-image-title-wrap" style={{ display: 'flex', flexDirection: 'column', width: "200px", margin: 'auto' }}>

                            {currentUrl === '/profile' ? (
                              !spinner ? (
                                <button type="button" className="btn" onClick={cropandUploadHandler}>
                                  Upload
                                </button>
                              ) : (
                                <button className="btn">
                                  <span className="loader"></span>
                                </button>
                              )
                            ) : (
                              <button type="button" className="btn" onClick={cropImage}>
                                SAVE
                              </button>
                            )}
                            <label className="remove-profile-image" style={{ marginTop: '10px' }} onClick={() => { setimagePath("") }}>Select a different photo</label>
                            {/* <input class="profile-upload-input" id="profile-upload" type='file' onchange=""
                          accept="image/*" /> */}
                          </div>
                          {/* <a
  
                            >Done</a> */}

                        </div>
                        //       <div className="profile-upload-content">
                        //         <div className="drag_image">
                        //           <div className="drag_image_inner">
                        //             <img className="profile-upload-image" id="upload-img" src={imagePath} alt="your image" />
                        //           </div>
                        //         </div>
                        //         <div className="profile-image-title-wrap">
                        //           <button type="button" className="btn">Post Photo</button>
                        //           <label className="remove-profile-image" onClick={()=>{setimagePath("")}}>Select a different photo</label>
                        //           {/* <input class="profile-upload-input" id="profile-upload" type='file' onchange=""
                        // accept="image/*" /> */}
                        //         </div>
                        //       </div>
                      }
                      {/* </form> */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* ! Upload Profile */}

    </>
  )
}

export default ProfileImgUploadModal

// const zoomPercent = (value) => {
//   return `${Math.round(value * 100)}%`;
// };
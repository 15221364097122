import axios from 'axios';
import React from 'react'
import { useState, useEffect } from 'react';
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom"
import { formatDate } from "../../utils/Formatteddate"
import { formatLikes } from "../../utils/FomattedLike"
import { useDispatch } from "react-redux";
import { ModalAction } from "../../Store/Modal-Slice";
import { ShortAddress } from '../../utils/Address';
import useImageLoader from '../../Hooks/useImageLoader';
import MainImageSkeleton from '../Skeleton/MainImageSkeleton'



const TopTenList = (props) => {
 
  let [votebtnactive, setvotebtnactive] = useState(props.voted || false)
  let [commentValue, setcommentValue] = useState("")
  let [PlaceholderValue, setPlaceholderValue] = useState(false)


  let dispatch = useDispatch()



  // formatDate function 
  const formattedDate = formatDate(props.postedDate);

  //// Like////

  const formattedLikes = formatLikes(props.TotalVotes);

  ///address
  let address = ShortAddress(props.Userlocation, 16);

  // ImageLoaded hook 
  const isImageLoaded = useImageLoader(props.img);

  // <-------------------------------------------->

  let navigate = useNavigate();

  let OtheruserProfileNavigateHandler = (event) => {
    event.preventDefault();

    if (props.loginUserid == props.Userid) {
      navigate("/profile");
    } else {
      navigate(`/otherusers-profile?photoid=${props.postId}&userid=${props.Userid}`);
    }

    window.scrollTo(0, 0)
  }

  let MainImgHandler = () => {
    dispatch(ModalAction.setPopupModalMessage({
      "joinModalHeading": "",
      "popupHeading": "You’re In.",
      "popupMessage": "Next, upvote the photo you were looking at. Give it a chance to be featured in our Tuesday email!",
      photoID: props.postId,
      userID: props.Userid,
    }))
  }

  useEffect(() => {
    setvotebtnactive(props.voted || false);

  }, [props.voted]);


  let commentInputHandler = (e) => {
    setcommentValue(e.target.value)
  }


  let SubmitHandler = async (event) => {
    event.preventDefault();


    try {
      const payload = {
        "photoID": props.postId,
        "commentText": commentValue
      };
      let headers = {
        'Authorization': `Bearer ${props.usertoken}`,
        'Content-Type': 'application/json'
      }
      const response = await axios.post(`${process.env.REACT_APP_BASE_URL}/comment/add`, payload, { headers });

     } catch (error) {
      console.error('Error:', error.response.data.message);
     }

    setcommentValue('')
    setPlaceholderValue(true)

  }


 


  return (
    <>
      <div className="feed_item">
        <div className="feed_item_inner">
          <Link to="" onClick={props.isLoggedIn ? OtheruserProfileNavigateHandler : MainImgHandler} data-bs-toggle={`${!props.isLoggedIn ? "modal" : ""}`} data-bs-target="#joinModal">
            {!isImageLoaded ? (
              <MainImageSkeleton />
            ) : (
              <img className='main-img img-fluid' src={props.img} alt="image" />
            )}
          </Link>

          <div className="feed_hover_text">
            <div className="feed_user">
              <Link
                className='user_img'
                onClick={props.isLoggedIn ? OtheruserProfileNavigateHandler : MainImgHandler}
                data-bs-toggle={`${!props.isLoggedIn ? "modal" : ""}`}
                data-bs-target="#joinModal"
                style={{
                  backgroundImage: `url(${props.UserPhoto || ""})`,
                  backgroundPosition: "center",
                  backgroundSize: "cover",
                  backgroundRepeat: "no-repeat",
                  borderRadius: "50%",
                  cursor: "pointer",
                }}
              >
                {!props.UserPhoto && <img src={`https://ui-avatars.com/api/?name=${props.Name}&size=40&rounded=true&background=01655a&color=fff`} />}
              </Link>
              <div className="user_text">
                <Link to="#" className="user_name" onClick={props.isLoggedIn ? OtheruserProfileNavigateHandler : MainImgHandler} data-bs-toggle={`${!props.isLoggedIn ? "modal" : ""}`} data-bs-target="#joinModal">
                  {props.Name}
                </Link>
                <p className="user_meta">
                  {address && <span className="location">{address}</span>}
                  <span className="date">{formattedDate}</span>
                </p>
              </div>
            </div>

            <div className="feed_vote">
              <Link
                  className={`btn btn-vote notAllow ${votebtnactive ? "active" : ""}`}>
                <img src="./assets/img/icons/arrow-up.svg" alt="" />
                {formattedLikes}
              </Link>
            </div>

          </div>

        </div>
        {props.isLoggedIn && <form onSubmit={SubmitHandler}>


          <div className="feed_comment">
            <div className="comment_user"
              style={{
                backgroundImage: `url(${props.displaySocailUserPhoto || ""})`,
                backgroundPosition: "center",
                backgroundSize: "cover",
                backgroundRepeat: "no-repeat",
                borderRadius: "50%",
                cursor: "pointer",
              }}
            >
              {!props.displaySocailUserPhoto && <img src={`https://ui-avatars.com/api/?name=${props.display_name}&size=40&rounded=true&background=01655a&color=fff`} />}
            </div>
            <div className="comment_field">
              <input type="text" name="comment" placeholder={!PlaceholderValue ? "Add feedback and make a photographer smile." : "Thanks! We’ve sent your feedback to the photographer. Watch here for our improved comment section soon!"} className={`${commentValue ? "typed" : ''}`} value={commentValue} onChange={commentInputHandler} />
              <button type="submit" className="comment-btn">
                <img src="./assets/img/icons/subtract.svg" />
              </button>
            </div>
          </div>

        </form>}
      </div>
    </>
  )
}

export default TopTenList
import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import { useDispatch } from "react-redux";
import { ModalAction } from "../../Store/Modal-Slice";

const Header = () => {
  let [MobileMenuState, setMobileMenuState] = useState(false)
  let [OverlayState, setOverlayState] = useState(false)


  let simpleloginAndsignupHandler = ()=>{
    dispatch(ModalAction.setPopupModalMessage({
      "joinModalHeading": "",
      "popupHeading": "",
      "popupMessage": "",
      photoID: "",
      userID: "",
  }))
  }

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    });
  };

  let dispatch = useDispatch()
  const currentUrl = window.location.pathname;

  let MenuHandler = () => {
    setMobileMenuState(true)
    setOverlayState(true)
  }

  let MenuCloseHandler = () => {
    setMobileMenuState(false)
    setOverlayState(false)
  }
  return (
    <>
      {OverlayState && <div class="overlay " onClick={MenuCloseHandler}></div>}

      <header className={`site-header ${currentUrl == '/' ? "without_login" : "sticky"} header-absolute`} id="site-header">
        {/* 
css:
without_login
with_login
     */}
        <div className="container">
          <div className="row">
            <div className="col">
              <div className="site_menu d-flex align-items-center flex-wrap">
                <div className="menu_bar">
                  <Link className='forDes'>
                    <img className="white_bar" src="./assets/img/icons/menu-bar-white.svg" alt="Menu Bar" />
                    <img className="black_bar" src="./assets/img/icons/menu-bar.svg" alt="Menu Bar" />
                  </Link>
                  <Link className='forMob' onClick={MenuHandler}>
                    <img className="white_bar" src="./assets/img/icons/menu-bar-white.svg" alt="Menu Bar" />
                    <img className="black_bar" src="./assets/img/icons/menu-bar.svg" alt="Menu Bar" />
                  </Link>
                  {/* Hamburger Menu */}
                  <nav className="side_menu d-none d-md-block">
                    <ul>
                      <li>
                        <Link to={'/about'} onClick={scrollToTop}>About</Link>
                      </li>
                      <li>
                        <Link to={'/how-works'} onClick={scrollToTop}>How It Works</Link>
                      </li>
                      {/* <li>
                        <Link >This Month’s Prize</Link>
                      </li> */}
                      <li>
                        {/* <Link to={"/giveaway"} onClick={scrollToTop}>Winners</Link> */}
                      </li>
                      <li>
                        <Link to={"/topten"} onClick={scrollToTop}>Weekly Top 10</Link>
                      </li>
                      <li>
                        <Link to={'/meet-terry'} onClick={scrollToTop}>A Mouse?</Link>
                      </li>
                      <li>
                        <Link to={'/contact'} onClick={scrollToTop}>Contact Us</Link>
                      </li>
                      <li>
                        <Link to="https://bigphotohunt.buzzsprout.com" target='_blank'>Podcast</Link>
                      </li>
                      {/* <li>
                        <Link to="#">FAQ</Link>
                      </li> */}
                      {/* <li>
                        <Link to={'/beta'} onClick={scrollToTop}>Beta</Link>
                      </li> */}
                    </ul>
                  </nav>
                </div>
                <div className="site_logo" onClick={scrollToTop}>
                  <Link to="/">
                    <img className="white_logo" src="./assets/img/logo/logo-white.png" alt="Site Logo" />
                    <img className="black_logo" src="./assets/img/logo/logo-black.png" alt="Site Logo" />
                  </Link>
                </div>
                <nav className="main_menu d-none d-md-block">
                  <ul>
                    <li>
                      <Link to={'/how-works'} onClick={scrollToTop}>How It Works</Link>
                    </li>
                    <li>
                      <Link to={'/meet-terry'} onClick={scrollToTop}>A Mouse?</Link>
                    </li>
                    <li>
                      <Link to={"/topten"} onClick={scrollToTop}>Weekly Top 10</Link>
                    </li>
                    {/* <li>
                      <Link to={"/giveaway"} onClick={scrollToTop}>Winners</Link>
                    </li> */}

                    <li>
                      <Link to={'/beta'} onClick={scrollToTop}>Beta</Link>
                    </li>
                  </ul>
                </nav>
                {/* without login */}
                <div className="menu_right without_login d-none d-md-flex">
                  <div className="button sign_in">
                    <Link
                      to="#"
                      className="btn btn-white"
                      data-bs-toggle="modal"
                      data-bs-target="#signInModal"
                      onClick={simpleloginAndsignupHandler}
                    >
                      Sign in
                    </Link>
                  </div>
                  <div className="button join_now">
                    <Link
                      className="btn"
                      data-bs-toggle="modal"
                      data-bs-target="#joinModal"
                      onClick={simpleloginAndsignupHandler}
                    >
                      Join now
                    </Link>
                  </div>
                </div>
                <div className="menu_right without_login d-md-none">
                  <div className="button sign_in">
                    <Link
                      to="#"
                      className="btn btn-white"
                      data-bs-toggle="modal"
                      data-bs-target="#signInModal"
                      onClick={simpleloginAndsignupHandler}
                    >
                      Sign in
                    </Link>
                  </div>
                  <div className="button join_now">
                    <Link
                      className="btn"
                      data-bs-toggle="modal"
                      data-bs-target="#joinModal"
                      onClick={simpleloginAndsignupHandler}
                    >
                      Join
                    </Link>
                  </div>
                </div>
                {/* !without login */}
                {/* with logged in */}
                {/* <div class="menu_right with_logged_in">
        <div class="button">
          <Link href="#" class="btn d-none d-lg-inline-block" data-bs-toggle="modal" data-bs-target="#photoUploadModal">Post Your Photo</Link>
          <Link href="#" class="btn plus d-lg-none"><img src="./assets/img/icons/plus.svg" alt=""></Link>
        </div>
        <div class="notification">
          <Link href="#"><img src="./assets/img/icons/bell.svg" alt="Bell"></Link>
        </div>
        <div class="user">
          <Link href="#"><img src="./assets/img/users/user-img.png" alt="User Image"></Link>
        </div>
      </div> */}
                {/* !logged in */}
              </div>
            </div>
          </div>
        </div>
        {/* Hamburger mobile Menu */}
        <nav className={`side_menu mobile d-md-none ${MobileMenuState ? "open" : ""}`}>
          <div className="side_menu_top">
            <Link href="" className="close" onClick={MenuCloseHandler}>
              <img src="./assets/img/icons/close.svg" />
            </Link>
          </div>
          <ul onClick={MenuCloseHandler}>
            <li>
              <Link to={'/about'} onClick={scrollToTop}>About</Link>
            </li>
            <li>
              <Link to={'/how-works'} onClick={scrollToTop}>How It Works</Link>
            </li>
            {/* <li>
              <Link>This Month’s Prize </Link>
            </li> */}
            <li>
              {/* <Link to={"/giveaway"} onClick={scrollToTop}>Winners</Link> */}
            </li>
            <li>
              <Link to={"/topten"} onClick={scrollToTop}>Weekly Top 10</Link>
            </li>
            <li>
              <Link to={'/meet-terry'} onClick={scrollToTop}>A Mouse?</Link>
            </li>
            <li>
              <Link to={'/contact'} onClick={scrollToTop}>Contact Us</Link>
            </li>
            <li>
              <Link to="https://bigphotohunt.buzzsprout.com" target='_blank'>Podcast</Link>
            </li>
            {/* <li>
              <Link to="#">FAQ</Link>
            </li> */}
            <li>
              <Link to={'/beta'} onClick={scrollToTop}>Beta</Link>
            </li>
          </ul>
        </nav>
      </header>

    </>
  )
}

export default Header
import  {configureStore}  from "@reduxjs/toolkit";
import SocialMediaSlice from "./SocialMedia-Slice";
import ModalSlice from "./Modal-Slice";
import LoginSlice from "./Login-Slice";
let store = configureStore({
    reducer:{
        SocialMedia: SocialMediaSlice.reducer,
        Modal:ModalSlice.reducer,
        Login:LoginSlice.reducer,
    
    }
})

export default store;
import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { decryptUserData } from '../../utils/encryptedData'
import { useSelector } from 'react-redux';
import { useDispatch } from "react-redux";
import { ModalAction } from "../../Store/Modal-Slice";
import { ShortAddress } from '../../utils/Address';
import ProfileInfoSkeleton from '../Skeleton/ProfileInfoSkeleton';



const ProfileInfo = () => {
  let [defaultUserInfo, setdefaultUserInfo] = useState('')
  let [GenresValue, setGenresValue] = useState('')
  let [Followers, setFollowers] = useState('')
  let [Following, setFollowing] = useState('')

  let MEApiState = useSelector(state => state.Modal.MeApiState)

  let address
  if (defaultUserInfo.state) {
    address = ShortAddress(defaultUserInfo.state, 21);
  }

  // <---------------- decrypted data ------------->
  const encryptedUserData = localStorage.getItem('Data');
  const decryptedUserData = decryptUserData(encryptedUserData);
  let Email = decryptedUserData.user_email
  // <-------------------------------------------->
  let dispatch = useDispatch()


  // <-------------------------------------------->
  function removeProtocolFromURL(url) {
    if (!url) {
      return "";
    }
    const protocolRegex = /^(https?:\/\/)/i;
    return url.replace(protocolRegex, '');
  }

  const modifiedURL = removeProtocolFromURL(defaultUserInfo.website);


  //changing date format
  const dateString = defaultUserInfo.createdAt;
  const date = new Date(dateString);

  const options = { day: '2-digit', month: '2-digit', year: 'numeric' };
  const formattedDate = date.toLocaleDateString('en-US', options);
  //


  const genresString = GenresValue ? Object.values(GenresValue).join(", ") : "";



  useEffect(() => {

    let me = async function () {



      try {
        const payload = {
          "email": Email,
        };

        const response = await axios.post(`${process.env.REACT_APP_BASE_URL}/auth/Me`, payload);

        setdefaultUserInfo(response.data.data[0])
        setGenresValue(response.data.data[0].genres_list)
        setFollowers(response.data.data[0].Followers)
        setFollowing(response.data.data[0].Following)
      } catch (error) {
        console.error('Error:', error.response.data.message);


      }
    }
    me();
  }, [MEApiState])




  return (
    <>
      <main className="site-content" id="main"  >
        {!defaultUserInfo ? <ProfileInfoSkeleton /> :
          <section className="profile-section">
            <div className="container">
              <div className="row">
                <div className="col-lg-6">
                  <div className="profile_info">
                    <div className="profile_info_inner">
                      {/* <div className="profile_img"
                    style={{
                      backgroundImage: `url(${defaultUserInfo.photo_url || ""})`,
                      backgroundPosition: "center",
                      backgroundSize: "cover",
                      backgroundRepeat: "no-repeat",
                      borderRadius: "50%",
                      cursor: "pointer",

                    }}>
                    {!defaultUserInfo.photo_url && <img src={`https://ui-avatars.com/api/?name=${defaultUserInfo.name}&size=40&rounded=true&background=01655a&color=fff`} alt="" />}
                  </div> */}

                      <div className="profile_img"
                        onClick={() => { dispatch(ModalAction.setProfileUploadModalState(true)) }}
                        style={{
                          backgroundImage: `url(${defaultUserInfo.photo_url || ""})`,
                          backgroundPosition: "center",
                          backgroundSize: "cover",
                          backgroundRepeat: "no-repeat",
                          borderRadius: "50%",
                          cursor: "pointer",

                        }}>
                        {!defaultUserInfo.photo_url && <img src={`https://ui-avatars.com/api/?name=${defaultUserInfo.name}&size=40&rounded=true&background=01655a&color=fff`} alt="" />}
                        <div className="change_img profile-upload_icon">
                          <img src="./assets/img/icons/Camera.svg" />
                        </div>
                      </div>
                      <div className="profile_info_text">
                        <div className="profile_name">
                          <h4 className="name">{defaultUserInfo.name}</h4>
                        </div>

                        {defaultUserInfo.facebook_link || defaultUserInfo.twitter_link || defaultUserInfo.instagram_link || defaultUserInfo.website ?
                          <div className="profile_contact">
                            <ul className="socials">
                              {defaultUserInfo.facebook_link && <li>
                                <Link to={`https://www.facebook.com/${defaultUserInfo.facebook_link}`} target="_blank">
                                  <img src="./assets/img/icons/facebook.svg" alt="" />
                                </Link>
                              </li>}
                              {defaultUserInfo.twitter_link && <li>
                                <Link to={`https://twitter.com/${defaultUserInfo.twitter_link}`} target="_blank">
                                  <img src="./assets/img/icons/twitter.svg" alt="" />
                                </Link>
                              </li>}
                              {defaultUserInfo.instagram_link && <li>
                                <Link to={`https://www.instagram.com/${defaultUserInfo.instagram_link}`} target="_blank">
                                  <img
                                    src="./assets/img/icons/instagram.svg"
                                    alt=""
                                  />
                                </Link>
                              </li>}
                            </ul>
                            {defaultUserInfo.website && <div className="website">
                              <Link to={defaultUserInfo.website} target="_blank">
                                {modifiedURL}
                              </Link>
                            </div>}
                          </div>
                          :
                          <Link to={'/edit-profile'} onClick={() => window.scrollTo(700, 700)}> <p style={{ color: '#0000FF' }}>Add Your Socials So People Can Find You</p></Link>
                        }

                        <div className="connected_with d-none d-md-flex">
                          <div class="following" >
                            <span class="title">Following</span>
                            <div class="following_users">
                              <Link to={'/follow'} onClick={()=>{window.scroll(0,0)}}>
                                <ul class="users">
                                  <li>
                                    <a href="#"><img src="./assets/img/users/user-1.png" alt="" /></a>
                                  </li>
                                  <li>
                                    <a href="#"><img src="./assets/img/users/user-2.png" alt="" /></a>
                                  </li>
                                  <li>
                                    <a href="#"><img src="./assets/img/users/user-3.png" alt="" /></a>
                                  </li>
                                </ul>
                              </Link>
                              <span class="count">+152</span>
                            </div>
                          </div>

                          <div class="followers" >
                            <span class="title">Followers</span>
                            <div class="follower_users">
                            <Link to={'/follow'} onClick={()=>{window.scroll(0,0)}}>
                                <ul class="users">
                                  <li>
                                    <a href="#"><img src="./assets/img/users/user-1.png" alt="" /></a>
                                  </li>
                                  <li>
                                    <a href="#"><img src="./assets/img/users/user-2.png" alt="" /></a>
                                  </li>
                                  <li>
                                    <a href="#"><img src="./assets/img/users/user-3.png" alt="" /></a>
                                  </li>
                                </ul>
                              </Link>
                              <span class="count">+502</span>
                            </div>
                          </div>

                          {/* <div className="following">
                        <span className="title">Following</span>
                        <div className="following_users">
                          {(Following.count > 0) && <ul className="users">
                            {Following.data && Following.data.map((url, index) => (
                              <li key={index} >
                                  <Link to=""  
                                  style={{
                                    backgroundImage: `url(${url.photo_url || ""})`,
                                    backgroundPosition: "center",
                                    backgroundSize: "cover",
                                    backgroundRepeat: "no-repeat",
                                    borderRadius: "50%",
                                    cursor: "pointer",
            
                                  }}>
                                      {!url.photo_url && <img  style={{ borderRadius: '50%',height:'100%',width:'auto' }} 
                                      src={ `https://ui-avatars.com/api/?name=${url.name}&size=40&rounded=true&background=01655a&color=fff`} alt="ddd" />}
                                  </Link>
                              </li>
                          ))

                          } 
                          </ul>}
                          <span className="count">{Following.count}</span>
                        </div>
                      </div> */}
                          {/* <div className="followers">
                        <span className="title">Followers</span>
                        <div className="follower_users">
                        {(Followers.count > 0) && <ul className="users">
                            {Followers.data && Followers.data.map((url, index) => (
                              <li key={index} >
                                  <Link to=""  
                                  style={{
                                    backgroundImage: `url(${url.photo_url || ""})`,
                                    backgroundPosition: "center",
                                    backgroundSize: "cover",
                                    backgroundRepeat: "no-repeat",
                                    borderRadius: "50%",
                                    cursor: "pointer",
            
                                  }}>
                                      {!url.photo_url && <img  style={{ borderRadius: '50%',height:'100%',width:'auto' }} 
                                      src={`https://ui-avatars.com/api/?name=${url.name}&size=40&rounded=true&background=01655a&color=fff`} alt="ddd" />}
                                  </Link>
                              </li>
                          ))} 
                          </ul>}
                          <span className="count">{Followers.count}</span>
                        </div>
                      </div> */}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="profile_details">
                    <div className="profile_details_inner">
                      <div className="details">
                        {defaultUserInfo.state && <div className="details_item from">
                          <span>From:</span>
                          <p>{address}</p>
                        </div>}
                        {(defaultUserInfo.camera_brand && defaultUserInfo.camera_brand !== "null") && <div className="details_item camera">
                          <span>Camera System:</span>
                          <p>{defaultUserInfo.camera_brand}</p>
                        </div>}
                        <div className="details_item since">
                          <span>Member since:</span>
                          <p>{formattedDate}</p>
                        </div>
                      </div>
                      <div className="details">
                        {(defaultUserInfo.photo_assistance && defaultUserInfo.photo_assistance !== "null") && <div className="details_item why">
                          <span>Why I'm Here:</span>
                          <p>{defaultUserInfo.photo_assistance}</p>
                        </div>}
                        {genresString && <div className="details_item genres">
                          <span>Genres:</span>
                          <p>{genresString}</p>
                        </div>}
                      </div>
                      <div className="profile_edit d-none d-md-block">
                        <Link className="btn btn-edit" to={'/edit-profile'}>
                          Edit Profile
                        </Link>
                      </div>
                    </div>

                    {/* <!-- mobile connected with --> */}
                    <div className="connected_with d-md-none">
                      <div class="following" >
                        <span class="title">Following</span>
                        <div class="following_users">

                          <Link to={'/follow'} onClick={() => { window.scroll(0, 0) }}>
                            <ul class="users">
                              <li>
                                <a href="#"><img src="./assets/img/users/user-1.png" alt="" /></a>
                              </li>
                              <li>
                                <a href="#"><img src="./assets/img/users/user-2.png" alt="" /></a>
                              </li>
                              <li>
                                <a href="#"><img src="./assets/img/users/user-3.png" alt="" /></a>
                              </li>
                            </ul>
                          </Link>
                          <span class="count">+152</span>
                        </div>
                      </div>

                      <div class="followers" >
                        <span class="title">Followers</span>
                        <div class="follower_users">
                          <Link to={'/follow'} onClick={() => { window.scroll(0, 0) }}>
                            <ul class="users">
                              <li>
                                <a href="#"><img src="./assets/img/users/user-1.png" alt="" /></a>
                              </li>
                              <li>
                                <a href="#"><img src="./assets/img/users/user-2.png" alt="" /></a>
                              </li>
                              <li>
                                <a href="#"><img src="./assets/img/users/user-3.png" alt="" /></a>
                              </li>
                            </ul>
                          </Link>
                          <span class="count">+502</span>
                        </div>
                      </div>

                      {/* <div className="following">
                    <span className="title">Following</span>
                    <div className="following_users">
                    {(Following.count > 0) && <ul className="users">
                            {Following.data && Following.data.map((url, index) => (
                              <li key={index} >
                                  <Link to=""  
                                  style={{
                                    backgroundImage: `url(${url.photo_url || ""})`,
                                    backgroundPosition: "center",
                                    backgroundSize: "cover",
                                    backgroundRepeat: "no-repeat",
                                    borderRadius: "50%",
                                    cursor: "pointer",
            
                                  }}>
                                      {!url.photo_url && <img  style={{ borderRadius: '50%',height:'100%',width:'auto' }} 
                                      src={`https://ui-avatars.com/api/?name=${url.name}&size=40&rounded=true&background=01655a&color=fff`} alt="ddd" />}
                                  </Link>
                              </li>
                          ))

                          } 
                          </ul>}
                          <span className="count">{Following.count}</span>
                    </div>
                  </div> */}
                      {/* <div className="followers">
                    <span className="title">Followers</span>
                    <div className="follower_users">
                    {(Followers.count > 0) && <ul className="users">
                            {Followers.data && Followers.data.map((url, index) => (
                              <li key={index} >
                                  <Link to=""  
                                  style={{
                                    backgroundImage: `url(${url.photo_url || ""})`,
                                    backgroundPosition: "center",
                                    backgroundSize: "cover",
                                    backgroundRepeat: "no-repeat",
                                    borderRadius: "50%",
                                    cursor: "pointer",
            
                                  }}>
                                      {!url.photo_url && <img  style={{ borderRadius: '50%',height:'100%',width:'auto' }} 
                                      src={ `https://ui-avatars.com/api/?name=${url.name}&size=40&rounded=true&background=01655a&color=fff`} alt="ddd" />}
                                  </Link>
                              </li>
                          ))} 
                          </ul>}
                          <span className="count">{Followers.count}</span>
                    </div>
                  </div> */}
                    </div>

                    {/* <!-- mobile edit btn --> */}
                    <div className="profile_edit d-md-none">
                      <Link className="btn btn-edit" to={'/edit-profile'}>
                        Edit Profile
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>}
      </main>

    </>
  )
}

export default ProfileInfo
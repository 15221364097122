// Import necessary modules
import React from 'react';
import Intercom from 'react-intercom';

// Create the IntercomChat component
const IntercomChat = ({ user }) => {
  // Prepare Intercom settings
  const intercomSettings = {
    api_base: "https://api-iam.intercom.io",
    app_id: "quto1rxu",  // Replace with your Intercom App ID
    ...user,  // Include user data if available
  };

  // Render the Intercom widget with the prepared settings
  return (
    <Intercom appID={intercomSettings.app_id} {...intercomSettings} />
  );
};

// Export the component
export default IntercomChat;

import { useState, useEffect } from 'react';

const useImageLoader = (imageUrl) => {
  
  const [isImageLoaded, setIsImageLoaded] = useState(false);

  useEffect(() => {
    const image = new Image();
    image.src = imageUrl;

    image.onload = () => {
      setIsImageLoaded(true);
    };
  }, [imageUrl]);

  return isImageLoaded;
};

export default useImageLoader;
import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import { useState } from "react";
import { decryptUserData } from '../../utils/encryptedData'
import { useSelector } from 'react-redux';
import { useDispatch } from "react-redux";
import { ModalAction } from "../../Store/Modal-Slice";
import { LoginAction } from '../../Store/Login-Slice';
import { useNavigate } from "react-router-dom";

const LoginHeader = (props) => {
  let [MobileMenuState, setMobileMenuState] = useState(false)
  let [OverlayState, setOverlayState] = useState(false)

  let dispatch = useDispatch()
  let navigate = useNavigate();

  let MEApiState = useSelector(state => state.Modal.MeApiState)
  // <---------------- decrypted data ------------->

  const encryptedUserData = localStorage.getItem('Data');
  let decryptedUserData = decryptUserData(encryptedUserData);
  let UserPhoto = decryptedUserData.Display_UserSocailPhoto;
  let display_name = decryptedUserData.useDisplay_name;


  if (MEApiState) {
    decryptedUserData = decryptUserData(encryptedUserData);
  }

   // <-------------------------------------------->

   let logoutHandler = (e) => {
    dispatch(LoginAction.setIsLoggedIn(false))
    localStorage.removeItem("Data")
    localStorage.removeItem("source")
    localStorage.removeItem("photoId")
    navigate('/', { replace: true })
    window.scrollTo(0, 0);
}


let MenuHandler = ()=>{
  setMobileMenuState(true)
  setOverlayState(true)
}

let MenuCloseHandler = ()=>{
  setMobileMenuState(false)
  setOverlayState(false)
}


  // <-------------------------------------------->


  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    });
  };



  

  return (
    <>

{OverlayState && <div class="overlay " onClick={MenuCloseHandler }></div>}


      <header className="site-header with_login" id="site-header">
        {/* <!-- 
      css:
      without_login
      with_login
     --> */}
        <div className="container">
          <div className="row">
            <div className="col">
              <div className="site_menu d-flex align-items-center flex-wrap">
                <div className="menu_bar">
                  <Link >
                    <img className="forDesktop" src="./assets/img/icons/menu-bar.svg" alt="Menu Bar" />
                    <img className="forMobile" onClick={MenuHandler} src="./assets/img/icons/menu-bar.svg" alt="Menu Bar" />
                  </Link>
                  {/* <!-- Hamburger Menu --> */}
                  <nav className="side_menu d-none d-md-block">
                    <ul>
                      <li>
                        <Link to={'/about'} onClick={scrollToTop}>About</Link>
                      </li>
                      <li>
                        <Link to={'/how-works'} onClick={scrollToTop}>How It Works</Link>
                      </li>
                      {/* <li>
                        <Link >This Month’s Prize </Link>
                      </li> */}
                      {/* <li>
                        <Link to={"/giveaway"} onClick={scrollToTop}>Winners</Link>
                      </li> */}
                      <li>
                        <Link to={"/topten"} onClick={scrollToTop}>Weekly Top 10</Link>
                      </li>
                      <li>
                        <Link to={'/meet-terry'} onClick={scrollToTop}>A Mouse?</Link>
                      </li>
                      <li>
                        <Link to={'/contact'} onClick={scrollToTop}>Contact Us</Link>
                      </li>
                      <li>
                        <Link to="https://bigphotohunt.buzzsprout.com" target='_blank'>Podcast</Link>
                      </li>
                      {/* <li>
                        <Link to="#" onClick={scrollToTop}>FAQ</Link>
                      </li> */}

                      {/* <li>
                        <Link to={'/beta'} onClick={scrollToTop}>Beta</Link>
                      </li> */}
                    </ul>
                  </nav>
                </div>
                <div className="site_logo">
                  <Link to="/">
                    <img
                      src="./assets/img/logo/logo-black.png"
                      alt="Site Logo"
                    />
                  </Link>
                </div>
                <nav className="main_menu d-none d-md-block">
                  <ul>
                    <li>
                      <Link to={'/how-works'} onClick={scrollToTop}>How It Works</Link>
                    </li>
                    <li>
                      <Link to={'/meet-terry'} onClick={scrollToTop}>A Mouse?</Link>
                    </li>
                    <li>
                      <Link to={'/topten'} onClick={scrollToTop}>Weekly Top 10</Link>
                    </li>
                    {/* <li>
                      <Link to={"/giveaway"} onClick={scrollToTop}>Winners</Link>
                    </li> */}
                    <li>
                      <Link to={'/beta'} onClick={scrollToTop}>Beta</Link>
                    </li>
                  </ul>
                </nav>
                {/* <!-- without login --> */}
                {/* <!-- <div className="menu_right without_login">
              <div className="button sign_in">
                <Link to="#" className="btn btn-white">Sign in</Link>
              </div>
              <div className="button join_now">
                <Link to="#" className="btn">Join now</Link>
              </div>
            </div> -->
						<!-- !without login --> */}

                {/* <!-- with logged in --> */}
                <div className="menu_right with_logged_in">
                  <div className="button">
                    {/* <input type="file" onChange={imgHandler} accept=".jpg, .jpeg, .png"></input> */}
                    {/* <button >post img</button> */}
                    <button
                      onClick={()=>{dispatch(ModalAction.setPhotoUploadModalState(true))}}
                      className="btn d-none d-lg-inline-block" >
                      Post Your Photo
                    </button>
                    <Link to="#" className="btn plus d-lg-none"  onClick={()=>{dispatch(ModalAction.setPhotoUploadModalState(true))}}>
                      <img src="./assets/img/icons/plus.svg" alt="" />
                    </Link>
                  </div>
                  <div className="notification">
                    <Link to={'/beta'}>
                      <img src="./assets/img/icons/bell.svg" alt="Bell" />
                    </Link>
                  </div>
                  <div className="user">
                    <Link to="/profile" onClick={() => { window.scrollTo(0, 0) }}
                      style={{
                        backgroundImage: `url(${UserPhoto || ""})`,
                        backgroundPosition: "center",
                        backgroundSize: "cover",
                        backgroundRepeat: "no-repeat",
                        borderRadius: "50%",
                        cursor: "pointer",

                      }}>
                      {!UserPhoto && <img style={{ borderRadius: "50%" }}
                        src={`https://ui-avatars.com/api/?name=${display_name}&size=40&rounded=true&background=01655a&color=fff`}
                        alt="User Pic"
                      />}
                    </Link>
                    <div class="profile_settings">
                  <ul>
                    <li class="active"><Link to="/profile" >Profile</Link></li>
                    <li><Link to="/edit-profile">Account</Link></li>
                    <li><Link onClick={logoutHandler}>Logout</Link></li>
                  </ul>
                </div>
                  </div>
                  {/* <button style={{ backgroundColor: "#ffd621", color: 'black', border: 'none', padding: "10px", borderRadius: "50px", marginLeft: "10px" }} onClick={logout}> Logout </button> */}
                </div>
                {/* <!-- !logged in --> */}
              </div>
            </div>
          </div>
        </div>
        {/* <!-- Hamburger mobile Menu --> */}
        <nav className={`side_menu mobile d-md-none ${MobileMenuState ? "open" : ""}`}>
          <div className="side_menu_top">
            <Link className="close" onClick={MenuCloseHandler }>
              <img src="./assets/img/icons/close.svg" alt="" />
            </Link>
          </div>
          <ul onClick={MenuCloseHandler}>
            <li>
              <Link to={'/about'} onClick={scrollToTop}>About</Link>
            </li>
            <li>
              <Link to={'/how-works'} onClick={scrollToTop}>How It Works</Link>
            </li>
            {/* <li>
              <Link  >This Month’s Prize </Link>
            </li> */}
            {/* <li>
              <Link to={"/giveaway"} onClick={scrollToTop}>Winners</Link>
            </li> */}
            <li>
              <Link to={"/topten"} onClick={scrollToTop}>Weekly Top 10</Link>
            </li>
            <li>
              <Link to={'/meet-terry'} onClick={scrollToTop}>A Mouse?</Link>
            </li>
            <li>
              <Link to={'/contact'} onClick={scrollToTop}>Contact Us</Link>
            </li>
            <li>
              <Link to="https://bigphotohunt.buzzsprout.com" target='_blank'>Podcast</Link>
            </li>
            {/* <li>
              <Link to="#" onClick={scrollToTop}>FAQ</Link>
            </li> */}
            <li>
              <Link to={'/beta'} onClick={scrollToTop}>Beta</Link>
            </li>
          </ul>
        </nav>
      </header>
    </>
  );
};

export default LoginHeader;

import React from 'react'

const CustomLoader = () => {
  return (
    <>
      <div style={{ width: "100%", height: "100vh", display: "flex", justifyContent: 'center', alignItems: 'center' }}>
        <span class="loader" style={{ width: "50px", height: "50px", borderTop: "3px solid #000000" }}></span>
      </div>
    </>
  )
}

export default CustomLoader
export function formatLikes(likeCount) {
    if (likeCount >= 1000000) {
      const millionLikes = (likeCount / 1000000).toFixed(1);
      return millionLikes.endsWith('.0') ? millionLikes.slice(0, -2) + 'M' : millionLikes + 'M';
    } else if (likeCount >= 1000) {
      const thousandLikes = (likeCount / 1000).toFixed(1);
      return thousandLikes.endsWith('.0') ? thousandLikes.slice(0, -2) + 'K' : thousandLikes + 'K';
    } else {
      return likeCount.toString();
    }
  }
import React from 'react';
import './App.css';
import { Routes, Route, useNavigate, Navigate } from "react-router-dom";
import { useState, useEffect } from 'react';
import Home from './Pages/Home';
import Header from './Components/Layout/Header';
import LoginHeader from './Components/Layout/LoginHeader';
import Footer from './Components/Footer';
import SignInModal from './Components/UI/SignInModal';
import JoinNowModal from './Components/UI/JoinNowModal';
import PhotoUploadModal from './Components/UI/PhotoUploadModal';
import ProfileNoPhoto from './Pages/ProfileNoPhoto';
import OtherUsersProfilePage from './Pages/OtherUsersProfilePage';
import { useDispatch, useSelector } from "react-redux";
import { LoginAction } from './Store/Login-Slice';
import SharePhotoModal from './Components/UI/SharePhotoModal';
import { useLocation } from "react-router-dom";
import { decryptUserData } from './utils/encryptedData'
import ReactGA from "react-ga"
import IntercomChat from './Components/Chat/IntercomChat';
import PopupModal from './Components/UI/PopupModal';
import TopTenPage from './Pages/TopTenPage';
import ProfileImgUploadModal from './Components/UI/ProfileImgUploadModal';
import CustomLoader from './Components/Skeleton/CustomLoader';
import ProfilePage from './Pages/ProfilePage';
import { SkeletonTheme } from 'react-loading-skeleton';
const LazyGiveawayPage = React.lazy(() => import('./Pages/GiveawayPage'))
const LazySharePhoto = React.lazy(() => import('./Pages/SharePhoto'))
const LazyHowWorkPage = React.lazy(() => import('./Pages/HowWorkPage'))
const LazyBetaPage = React.lazy(() => import('./Pages/BetaPage'))
const LazyEditProfilePage = React.lazy(() => import('./Pages/EditProfilePage'))
const LazyMeetTerryPage = React.lazy(() => import('./Pages/MeetTerryPage'))
const LazyPrivacyPolicyPage = React.lazy(() => import('./Pages/PrivacyPolicyPage'))
const LazyTermsAndConditionsPage = React.lazy(() => import('./Pages/TermsAndConditionsPage'))
const LazyContactPage = React.lazy(() => import('./Pages/ContactPage'))
const LazyAboutPage = React.lazy(() => import('./Pages/AboutPage'))
const LazyFollowPage = React.lazy(() => import('./Pages/FollowPage'))


function App() {

  const location = useLocation();

  //  <---------------- decrypt data -------------> 
  const encryptedUserData = localStorage.getItem('Data');
  const decryptedUserData = decryptUserData(encryptedUserData);
  let storeUserLoggedInInformation = decryptedUserData.isLoggedIn

  // <------------------------------------> 
  document.addEventListener('contextmenu', function (e) {
    e.preventDefault();
  });
  //  <---------------- Google Analytics -------------> 
  const GoogleAnalyticsID = "G-XD16TFXGXR"
  ReactGA.initialize(GoogleAnalyticsID)
  // <------------------------------------> 

  let navigate = useNavigate();
  let dispatch = useDispatch()
  let isLogginState = useSelector(state => state.Login.isLoggedIn)
  let PopupState = useSelector(state => state.Modal.PopupModalState)
  let SignInState = useSelector(state => state.Modal.SignInModalState)
  // let JoinNowState = useSelector(state => state.Modal.JoinNowModalState)
  let PhotoUploadState = useSelector(state => state.Modal.PhotoUploadModalState)
  let ProfileUploadState = useSelector(state => state.Modal.ProfileUploadModalState)
  let SharePhotoState = useSelector(state => state.Modal.SharePhotoModalState)


  useEffect(() => {

    if (storeUserLoggedInInformation == true) {
      // setIsLoggedIn(true);
      dispatch(LoginAction.setIsLoggedIn(true))
    }
  }, [])


  const user = storeUserLoggedInInformation
    ? {
      name: decryptedUserData.useDisplay_name,
      email: decryptedUserData.user_email,
      created_at: decryptedUserData.CreatedAt,
    }
    : null;



  return (
    <>
      <SkeletonTheme baseColor="#ebebeb" highlightColor="#dad5d5">
        {<SignInModal />}
        <JoinNowModal />
        {PhotoUploadState && <PhotoUploadModal />}
        {ProfileUploadState && <ProfileImgUploadModal />}
        {SharePhotoState && <SharePhotoModal />}
        {PopupState && <PopupModal />}
        {!isLogginState && <Header />}
        {isLogginState && <LoginHeader />}

        {<IntercomChat user={user} />}


        <Routes>

          <Route path="/" element={<Home />} />

          <Route path="/profile" element={storeUserLoggedInInformation ? (<ProfilePage />) : <Navigate to='/' />} />

          <Route path="/profilenophoto" element={storeUserLoggedInInformation ? (<ProfileNoPhoto />) : <Navigate to='/' />} />

          <Route path="/otherusers-profile" element={storeUserLoggedInInformation ? (<OtherUsersProfilePage />) : <Navigate to='/' />} />

          <Route path="/topten" element={<TopTenPage />} />

          <Route path="/Photo" element={
            <React.Suspense fallback={<CustomLoader />}>
              <LazySharePhoto />
            </React.Suspense>
          } />


          <Route path="/about"
            element={
              <React.Suspense fallback={<CustomLoader />}>
                <LazyAboutPage />
              </React.Suspense>
            } />

          <Route path="/contact"
            element={
              <React.Suspense fallback={<CustomLoader />}>
                <LazyContactPage />
              </React.Suspense>
            } />

          <Route path="/beta" element={
            <React.Suspense fallback={<CustomLoader />}>
              <LazyBetaPage />
            </React.Suspense>
          } />

          <Route path="/terms" element={
            <React.Suspense fallback={<CustomLoader />}>
              <LazyTermsAndConditionsPage />
            </React.Suspense>
          } />

          <Route path="/policy" element={
            <React.Suspense fallback={<CustomLoader />}>
              <LazyPrivacyPolicyPage />
            </React.Suspense>
          } />

          <Route path="/how-works" element={
            <React.Suspense fallback={<CustomLoader />}>
              <LazyHowWorkPage />
            </React.Suspense>
          } />

          <Route path="/meet-terry" element={
            <React.Suspense fallback={<CustomLoader />}>
              <LazyMeetTerryPage />
            </React.Suspense>
          } />

          <Route path="/edit-profile" element={
            storeUserLoggedInInformation ?
              <React.Suspense fallback={<CustomLoader />}>
                <LazyEditProfilePage />
              </React.Suspense>
              : <Navigate to='/'
              />} />

          <Route path="/giveaway" element={
            storeUserLoggedInInformation ?
              <React.Suspense fallback={<CustomLoader />}>
                <LazyGiveawayPage />
              </React.Suspense>
              : <Navigate to='/'
              />} />

          <Route path="/follow" element={
            storeUserLoggedInInformation ?
              <React.Suspense fallback={<CustomLoader />}>
                <LazyFollowPage />
              </React.Suspense>
              : <Navigate to='/'
              />} />


        </Routes>


        <Footer />
      </SkeletonTheme>
    </>
  );
}

export default App;

import React from 'react'
import FavouritePhotos from './FavouritePhotos'
import Members from './Members'
const PhotoFeed = () => {
  return (
   <>
    <section className="photo-feed-section" style={{marginTop:"40px"}}>
        <div className="container">
          <div className="row">
            <div className="col-md-8 col-lg-8">
              <FavouritePhotos />
            </div>
            <div className="col-lg-1 d-none d-lg-block">
              <div className="line"></div>
            </div>
            <div className="col-md-4 col-lg-3 d-none d-md-block">
              <Members />
            </div>
          </div>
        </div>
      </section>
   
   </>
  )
}

export default React.memo(PhotoFeed)
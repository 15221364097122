import { createSlice } from "@reduxjs/toolkit";

let initialLoginState = { isLoggedIn: false };

let LoginSlice = createSlice({
    name: 'Login',
    initialState:initialLoginState,
    reducers: {
        setIsLoggedIn(state, action) {
            state.isLoggedIn = action.payload
        }
    }
})

export let LoginAction = LoginSlice.actions;
export default LoginSlice
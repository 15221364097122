import { createSlice } from "@reduxjs/toolkit";

let initialModalState = {
    JoinModalBackbtn: false,
    StateAfterUploadPhoto: false,
    ShareModalPhotoInfo: "",
    PopupModalMessage: "",
    PopupModalState: false,
    PhotoUploadModalState: false,
    ProfileUploadModalState: false,
    SharePhotoModalState: false,
    CropImg:"",
    MeApiState:false
};

let ModalSlice = createSlice({
    name: 'Modal',
    initialState: initialModalState,
    reducers: {
        setJoinModalBackbtn(state, action) {
            state.JoinModalBackbtn = action.payload
        },
        setStateAfterUploadPhoto(state, action) {
            state.StateAfterUploadPhoto = action.payload
        },
        setShareModalPhotoInfo(state, action) {
            state.ShareModalPhotoInfo = action.payload
        },
        setPopupModalState(state, action) {
            state.PopupModalState = action.payload
        },
        setPhotoUploadModalState(state, action) {
            state.PhotoUploadModalState = action.payload
        },
        setProfileUploadModalState(state, action) {
            state.ProfileUploadModalState = action.payload
        },
        setSharePhotoModalState(state, action) {
            state.SharePhotoModalState = action.payload
        },
        setPopupModalMessage(state, action) {
            state.PopupModalMessage = action.payload
        },
        setCropImg(state, action) {
            state.CropImg = action.payload
        },
        setMeApiState(state, action) {
            state.MeApiState = action.payload
        },
    }
})

export let ModalAction = ModalSlice.actions;

export default ModalSlice


export const cameraTypes = [

    {

        value: 'Canon',
        displayText: 'Canon'

    },
    {

        value: 'Olympus',
        displayText: 'Olympus'

    },

    {

        value: 'Fuji',
        displayText: 'Fuji'

    },

    {

        value: 'Pentax',
        displayText: 'Pentax'

    },

    {

        value: 'Leica',
        displayText: 'Leica'

    },

    {

        value: 'Sony',
        displayText: 'Sony'

    },

    {

        value: 'Lumix',
        displayText: 'Lumix'

    },
    {

        value: 'Smartphone',
        displayText: 'Smartphone'

    },

    {

        value: 'Nikon',
        displayText: 'Nikon'

    },

    {

        value: 'other',
        displayText: 'Other'

    },





]

export const q1List = [
    {

        value: 'A professional',
        

    },
    {

        value: 'A hobbyist having fun',
        

    },
    {

        value: "I'm just learning",
        

    },
    {

        value: 'I post on social media only',
        

    },
    {

        value: 'I just like to look',
        

    },
    {

        value: "I'm only here supporting a friend",
        

    },
   
]

export const q2List = [
    {

        value: '1',
        

    },
    {

        value: '2',
        

    },
    {

        value: "3",
        

    },
    {

        value: '4',
        

    },
    {

        value: '5',
        

    },
    {

        value: "6",
        

    },
    {

        value: "7",
        

    },
    {

        value: "8",
        

    },
    {

        value: "9",
        

    },
    {

        value: "10",
        

    },
  
   
]

export const q5List = [
    {

        value: 'Discover Photographers',
        

    },
    {

        value: 'Inspiration',
        

    },
    {

        value: "Exposure For My Photos",
        

    },
    {

        value: 'Meet Other Photographers',
        

    },
    {

        value: 'Get Feedback',
        

    },
    {

        value: "Something Else",
        

    },
    {

        value: "Grow My Social Media",
        

    },
    {

        value: "Tips From Others",
        

    },
    {

        value: "Improve My Photography",
        

    },
    {

        value: "Win Cool Stuff",
        

    },
   
  
  
   
]
export const WhyIamhereList = [
    {
        value: 'Discover Photographers',
    },
    {
        value: 'Exposure For My Photos',
    },
    {
        value: 'Get Feedback',
    },
    {
        value: 'Grow My Social Media',
    },
    {
        value: 'Improve My Photography',
    },
    {
        value: 'Inspiration',
    },
    {
        value: 'Meet New People',
    },
    {
        value: 'Tips From Others',
    },
    {
        value: 'Something Else',
    },
    {
        value: 'Win Cool Stuff',
    },
]

export const GenresList = [

    {

        value: 'Abstract',
        id:"Abstract1",
         

    },
    {

        value: 'Landscape',
        id: 'Landscape1',
         

    },

    {

        value: 'Aerial',
        id: 'Aerial1',
         

    },

    {

        value: 'Website',
        id: 'Website1',
         

    },

    {

        value: 'Astro',
        id: 'Astro1',
         

    },

    {

        value: 'Nature',
        id: 'Nature1',
         

    },

    {

        value: 'Documentary',
        id: 'Documentary1',
         

    },
    {

        value: 'Portrait',
        id: 'Portrait1',
         

    },

    {

        value: 'Drone',
        id: 'Drone1',
         

    },

    {

        value: 'Product',
        id: 'Product1',
         

    },

    {

        value: 'Events',
        id: 'Events1',
         

    },

    {

        value: 'Sport',
        id: 'Sport1',
         

    },

    {

        value: 'Art',
        id: 'Art1',
         

    },
    {

        value: 'Street',
        id: 'Street1',
         

    },
    {

        value: 'Food',
        id: 'Food1',
         

    },
    {

        value: 'Travel',
        id: 'Travel1',
         

    },
    {

        value: 'Other',
        id: 'Other1',
         

    },
   

]








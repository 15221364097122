import React, { useEffect, useContext } from "react";
import { Link } from "react-router-dom";
import { useState } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import ReCAPTCHA from "react-google-recaptcha";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { LoginAction } from "../../Store/Login-Slice";
import { cameraTypes } from "../../utils/enums/listing";
import { q1List } from "../../utils/enums/listing";
import { q2List } from "../../utils/enums/listing";
import { q5List } from "../../utils/enums/listing";
import { GenresList } from "../../utils/enums/listing";
import { enc, AES } from 'crypto-js';
import { ModalAction } from "../../Store/Modal-Slice";
import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from 'react-places-autocomplete';






const JoinNowModal = (props) => {
  const [enteredEmail, setEnteredEmail] = useState('');
  const [emailIsValid, setEmailIsValid] = useState(false);
  const [checkingEmailIsempty, setcheckingEmailIsempty] = useState(false);
  const [enteredFullname, setEnteredFullname] = useState();
  const [FullnameIsValid, setFullnameIsValid] = useState('');
  const [enteredPassword, setEnteredPassword] = useState('');
  const [passwordIsValid, setPasswordIsValid] = useState();
  const [bothPassAndFullNameIsValid, setbothPassAndFullNameIsValid] = useState();
  const [enteredQ1, setEnteredQ1] = useState('');
  const [enteredQ2, setEnteredQ2] = useState('');
  const [enteredQ3, setEnteredQ3] = useState([]);
  const [enteredQ4, setEnteredQ4] = useState('');
  const [enteredQ5, setEnteredQ5] = useState('');
  const [skipModalState, setskipModalState] = useState(false);
  let [spinner, setspinner] = useState(false)
  let [error, seterror] = useState(false)
  const [address, setAddress] = useState('');
  const [validAddress, setvalidAddress] = useState(true);
  const [selectAdress, setselectAdress] = useState(false);


  let navigate = useNavigate();
  let JoinbackbtnState = useSelector(state => state.Modal.JoinModalBackbtn)
  let UserSocialInfo = useSelector(state => state.SocialMedia.SocialMediaUserInfo)
  let joinModalHeading = useSelector(state => state.Modal.PopupModalMessage.joinModalHeading)
  let dispatch = useDispatch()




  let emailChangeHandler = (e) => {
    let value = e.target.value;
    setEnteredEmail(value)
    // setEmailIsValid(e.target.value.includes('@'));
    setcheckingEmailIsempty(false)
  }

  // let validateEmailHandler = () => {
  //   setEmailIsValid(enteredEmail.includes('@'));
  // };

  let FullnameChangeHandler = (e) => {
    let value = e.target.value;
    setEnteredFullname(value)
    setFullnameIsValid(e.target.value.length > 0);
    setbothPassAndFullNameIsValid(e.target.value.length > 0 && enteredPassword.length > 7)
  }

  let passwordChangeHandler = (e) => {
    let value = e.target.value;
    setEnteredPassword(value)
    setPasswordIsValid(e.target.value.length > 7);
    setbothPassAndFullNameIsValid(e.target.value.length > 7 && enteredFullname.length > 0)
  }

  // ......................Location .................
  const handleChange = (newAddress) => {
    setAddress(newAddress);
    setselectAdress(!newAddress ? false:"")

  };

  const handleSelect = async (selectedAddress) => {
    setAddress(selectedAddress);
    try {
      setselectAdress(true)
      const results = await geocodeByAddress(selectedAddress);

    } catch (error) {
      console.error('Error while geocoding:', error);
    }
  };
  // ......................

  let Q1handler = (index) => {
    let value = q1List[index].value;
    setEnteredQ1(value)
    if (q1List[index].value === "I just like to look" || q1List[index].value === "I'm only here supporting a friend") {
      setskipModalState(true)
    } else {
      setskipModalState(false)

    }
  }

  let Q2handler = (index) => {
    let value = q2List[index].value;
    setEnteredQ2(value)
  }



  let Q3handler = (e) => {
    let value = e.target.value
    let checked = e.target.checked
    if (checked) {
      setEnteredQ3([...enteredQ3, value])
    } else {
      setEnteredQ3(enteredQ3.filter((e) => (e !== value)))
    }
   
  }




  let Q4handler = (index) => {
    let value = cameraTypes[index].value //e.target.value;
    setEnteredQ4(value)
  }

  let Q5handler = (index) => {
    let value = q5List[index].value //e.target.value;
    setEnteredQ5(value)
  }


  /////////


  ////////////recaptcha funtion

  function onChange(value) {
    console.log("Captcha value:", value);
  }



  let CheckingUserExistHandler = async (event) => {
    event.preventDefault();
    let Email = enteredEmail.toLowerCase();
    let userEmail = Email;
    let emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!Email) {
      setcheckingEmailIsempty(true)
      return
    } else if (!emailPattern.test(Email)) {
      // alert('Please enter your valid email')

      setEmailIsValid(true);

      return
    }
    try {
      const payload = {
        "email": userEmail,
      };

      const response = await axios.post(`${process.env.REACT_APP_BASE_URL}/auth/Me`, payload);

      seterror("This user already exists. Please sign in below.")



    } catch (error) {

      if (error.response.data.message == "User Not Exist") {
        let joinAlldiv = document.querySelectorAll(".wizardStep")
        let join_step_moda = document.querySelector(".join_step_modal")
        joinAlldiv.forEach((div) => {
          div.classList.remove("show")
        })
        join_step_moda.classList.add("show")
        seterror('')
      }

    }

  }







  let JoinHandler = async (event) => {
    event.preventDefault();





    let Email = enteredEmail.toLowerCase();
    let Fullname = enteredFullname;
    let Password = enteredPassword;
    let Q1 = enteredQ1;
    let Q2 = enteredQ2;
    let Q3Array = enteredQ3;
    let Q3myString = Q3Array.join(',');
    let Q4 = enteredQ4;
    let Q5 = enteredQ5;



    let userFullName = UserSocialInfo[2] || Fullname;
    let userEmail = UserSocialInfo[1] || Email;
    let lowercaseEmail = userEmail.toLowerCase()
    let userEmailType = UserSocialInfo[0] || "email";
    let userPassword = Password || '1234';
    let userPicture = UserSocialInfo[3];
    let userQ1Answer = Q1;
    let userQ2Answer = Q2;
    let userQ3Answer = Q3myString;
    let userQ4Answer = Q4;
    let userQ5Answer = Q5;



    // console.log(userFullName, lowercaseEmail, userEmailType, userPassword, userQ1Answer, userQ2Answer, userQ3Answer, userQ4Answer, userQ5Answer, userPicture,address)

    let getSource = localStorage.getItem("source");
    setspinner(true)
    try {
      let payload;
      if (!skipModalState) {
        payload = {
          name: userFullName,
          email: lowercaseEmail,
          userName: lowercaseEmail,
          password: userPassword,
          photo_url: userPicture || "",
          source: getSource || "",
          userType: userEmailType,
          photograperType: userQ1Answer,
          photoDesireRatio: Number(userQ2Answer),
          genresList: userQ3Answer,
          cameraBrand: userQ4Answer,
          photo_assistance: userQ5Answer,
          isEmailVerified: false,
          isProvidedDetailedInfo: false,
          isRegistered: false,
          facebookLink: "",
          twitterLink: "",
          instagramLink: "",
          website: "",
          state: address,
          country: "",
        };
      } else {
        payload = {
          name: userFullName,
          email: lowercaseEmail,
          photo_url: userPicture || "",
          userName: lowercaseEmail,
          password: userPassword,
          userType: userEmailType,
          photograperType: userQ1Answer,
          state: address,
          facebookLink: "",
          twitterLink: "",
          instagramLink: "",

        };
      }


      const response = await axios.post(`${process.env.REACT_APP_BASE_URL}/auth/registerUser`, payload);

 
      let token = response.data.data[1].token

      setspinner(false)

      if (token) {
        const userData = {
          userid: response.data.data[0].id,
          useDisplay_name: response.data.data[0].name,
          user_email: response.data.data[0].email,
          user_type: response.data.data[0].user_type,
          token: token,
          Display_UserSocailPhoto: response.data.data[0].photo_url,
          CreatedAt: response.data.data[0].createdAt,
          isLoggedIn: true
        };
        const secretKey = 'siddiqui';
        const encryptedUserData = AES.encrypt(JSON.stringify(userData), secretKey).toString();
        localStorage.setItem('Data', encryptedUserData);

        dispatch(LoginAction.setIsLoggedIn(true))
        dispatch(ModalAction.setPopupModalState(true))
        if (!localStorage.getItem("source")) {
          navigate('/', { replace: true })
        }
        window.scrollTo(0, 0);

        let joinAlldiv = document.querySelectorAll(".wizardStep")
        let joinstep1 = document.querySelector(".join_modal")
        joinAlldiv.forEach((div) => {
          div.classList.remove("show")
        })
        joinstep1.classList.add("show")

        setEnteredEmail('')
        setEnteredFullname('')
        setEnteredPassword('')
        setEmailIsValid()
        setPasswordIsValid()

        document.querySelector('.joinModalClose').click();
        document.querySelector('.skipclose').click();

      }

    } catch (error) {
     
      console.log(error)
      setspinner(false)
     }



  }

  return (
    <>
      <form onSubmit={JoinHandler} noValidate>
        <div
          className="modal fade bph-modal"
          id="joinModal"
          tabIndex={-1}
          aria-labelledby="joinModal"
          aria-hidden="true"
        >
          <div className="modal-dialog modal-dialog-centered">
            <div className="modal-content">
              <div className="modal-body">
                {/* <!-- join --> */}
                <div className="onboarding_modal wizardStep show join_modal">
                  <div className="modal_inner">
                    <button
                      type="button"
                      className="btn-close joinModalClose"
                      data-bs-dismiss="modal"
                      aria-label="Close"
                    // onClick={hHandler}
                    ></button>
                    {/* <!-- logo --> */}
                    <div className="modal_logo d-none d-md-inline-flex">
                      <img src="./assets/img/logo/modal-logo.png" alt="" />
                    </div>

                    {/* <!-- content --> */}
                    <div className="modal_content">
                      <div className="modal_title">
                        <h4 className="title">Join the club {joinModalHeading} </h4>
                      </div>

                      {/* <!-- log reg button --> */}
                      <div className="log_reg_btn">

                        <Link to="#" className="button facebook" onClick={() => { document.querySelector('.faceboook').click(); }}>
                          <img src="./assets/img/icons/fb.svg" />
                          Sign up with Facebook
                        </Link>
                        <Link to="#" className="button google" onClick={() => { document.querySelector('.google').click(); }}>
                          <img src="./assets/img/icons/google.svg" />
                          Sign up with Google
                        </Link>


                        {/* <Link to="#" className="button apple">
                          <img src="./assets/img/icons/apple.svg" alt="" />
                          Sign up with Apple ID
                        </Link> */}
                      </div>

                      {/* <!-- divider --> */}
                      <div className="divider">
                        <span>or via email</span>
                      </div>

                      {/*------------------ alert------------- */}




                      {/* <!-- form --> */}
                      {error && <p style={{textAlign:'center',color:'red'}}>{error}</p>}
                      <div className="form">
                        <div action="#" className="join_form">
                          <div className="form_group">
                            <input
                              type="email"
                              // name="email"
                              id="email"
                              placeholder="Email"
                              value={enteredEmail}
                              onChange={emailChangeHandler}
                            // onBlur={validateEmailHandler}

                            />
                            <div className="form_error"></div>
                          </div>
                          {emailIsValid ? <p className="eerror">Please enter your valid email (example@mail.com)</p> : ''}
                          {checkingEmailIsempty ? <p className="eerror">Please enter your email</p> : ''}

                          <div className="buttons">


                            <button
                              // disabled={!emailIsValid}
                              // nextStep
                              className="btn "
                              type="button"
                              onClick={CheckingUserExistHandler}
                            >
                              Join
                            </button>
                          </div>
                        </div>
                      </div>

                      {/* <!-- privacy text --> */}
                      <div className="privacy_text">
                        <p>
                          We will never post to any of your accounts without your
                          permission
                        </p>
                      </div>

                      {/* <!-- have account --> */}
                      <div className="have_account">
                        <p>
                          Have an account?
                          <Link
                            onClick={() => { document.querySelector('.joinModalClose').click(); }}
                            style={{ marginLeft: "5px" }}
                            data-bs-toggle="modal"
                            data-bs-target="#signInModal"
                          >
                            Sign in
                          </Link>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>

                {/* <!-- join step --> */}
                <div className="onboarding_modal wizardStep join_step_modal ">
                  <div className="modal_inner">
                    <button
                      type="button"
                      className="btn-close"
                      data-bs-dismiss="modal"
                      aria-label="Close"
                    ></button>
                    {/* <!-- logo --> */}
                    <div className="modal_logo d-none d-md-inline-flex">
                      <img src="./assets/img/logo/modal-logo.png" alt="" />
                    </div>

                    {/* <!-- content --> */}
                    <div className="modal_content">
                      <div className="modal_title">
                        <h4 className="title">Join the club {joinModalHeading}</h4>
                      </div>

                      {/* <!-- form --> */}
                      <div className="form">
                        <div action="#" className="join_step_form">
                          <div className="form_group">
                            <label htmlFor="fullname">Full Name</label>
                            <input type="text" id="fullname" maxLength={20} value={enteredFullname} onChange={FullnameChangeHandler} />
                            <div className="form_error"></div>
                          </div>
                          {FullnameIsValid === false ? <p className="eerror">Please enter your Name</p> : ''}

                          <div className="form_group">
                            <label htmlFor="password">Choose a Password</label>
                            <input
                              type="password"
                              name="password"
                              id="password"
                              value={enteredPassword}
                              onChange={passwordChangeHandler}
                            // onBlur={validatePasswordHandler}
                            />
                            <div className="form_error"></div>
                          </div>
                          {passwordIsValid === false ? <p className="eerror">Password must be at least 8 characters</p> : ''}
                          <div className="form_group captcha">


                            <ReCAPTCHA
                              sitekey="6LeIxAcTAAAAAJcZVRqyHh71UMIEGNQ_MXjiZKhI"
                              onChange={onChange}
                            />


                            {/* <img src="./assets/img/captcha/captcha.png" alt="" /> */}
                          </div>

                          <div className="buttons">
                            <Link
                              className="btn btn-edit previousStep"

                            >
                              Back
                            </Link>
                            <button
                              type="button"
                              className="btn nextStep"

                              disabled={!bothPassAndFullNameIsValid}

                            >
                              Get started
                            </button>
                          </div>
                        </div>
                      </div>

                      {/* <!-- privacy text --> */}
                      <div className="privacy_text">
                        <p>
                          We will never post to any of your accounts without your
                          permission
                        </p>
                      </div>
                    </div>
                  </div>
                </div>

                {/* <!-- question step 1 --> */}
                <div className="onboarding_modal wizardStep ques_step_modal step-1">
                  <div className="modal_inner">
                    {/* <!-- data-bs-dismiss="modal" --> */}
                    <button
                      type="button"
                      className="btn-close"
                      aria-label="Close"
                      data-bs-toggle="modal"
                    // data-bs-target="#skipModal"
                    ></button>
                    {/* <!-- logo --> */}
                    <div className="modal_logo d-none d-md-inline-flex">
                      <img src="./assets/img/logo/modal-logo.png" alt="" />
                    </div>

                    {/* <!-- content --> */}
                    <div className="modal_content">
                      <div className="modal_title">
                        <h4 className="title">Only 5 quick questions</h4>
                        <p className="subtitle">
                          So we can tailor your experience for you.
                        </p>
                        <div className="count">
                          <span>
                            <span className="active">1</span> / 5
                          </span>
                        </div>
                      </div>

                      {/* <!-- form --> */}
                      <div className="form">
                        <div className="ques_step_form">
                          <div className="form_title">
                            <p>
                              Which type of photographer do you consider yourself?
                            </p>
                          </div>
                          {/* <div className="form_group radio">
                            <input type="radio" name="photoType" id="type5" value="I just like to look" onChange={Q1handler} />
                            <label htmlFor="type5">I just like to look</label>
                          </div> */}

                          {q1List.map((item, index) => (
                            <div className="form_group radio" key={index}>
                              <input type="radio" name='photoType' id={item.value} value={item.value} onChange={() => Q1handler(index)} />
                              <label htmlFor={item.value}>{item.value}</label>
                            </div>
                          ))

                          }


                          <div className="buttons">
                            <button
                              className="btn btn-edit previousStep"

                              // disabled={Ctx.modalbackbtnstate}
                              disabled={JoinbackbtnState}

                              type="button"
                            >
                              Back
                            </button>
                            <button
                              type="button"
                              className={`btn ${!skipModalState ? "nextStep" : ""}`}
                              onClick={skipModalState
                                ? () => {
                                  document.querySelector(".step-2").classList.remove("show");
                                  document.querySelector(".step-6").classList.add("show");
                                }
                                : undefined
                              }

                              disabled={!enteredQ1}
                            >
                              Next
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                {/* <!-- question step 2 --> */}
                <div className="onboarding_modal wizardStep ques_step_modal step-2">
                  <div className="modal_inner">
                    <button
                      type="button"
                      className="btn-close"
                      aria-label="Close"
                      data-bs-dismiss="modal"
                    ></button>
                    {/* <!-- logo --> */}
                    <div className="modal_logo d-none d-md-inline-flex">
                      <img src="./assets/img/logo/modal-logo.png" alt="" />
                    </div>

                    {/* <!-- content --> */}
                    <div className="modal_content">
                      <div className="modal_title">
                        <h4 className="title">Only 5 quick questions</h4>
                        <p className="subtitle">
                          So we can tailor your experience for you.
                        </p>
                        <div className="count">
                          <span>
                            <span className="active">2</span> / 5
                          </span>
                        </div>
                      </div>

                      {/* <!-- form --> */}
                      <div className="form">
                        <div action="#" className="ques_step_form step-2">
                          <div className="form_title">
                            <p>
                              How often do you get that burning itch to take photos?
                            </p>
                            <span>Not all often</span>
                          </div>
                          <div className="radios">

                            {q2List.map((item, index) => (
                              <div className="form_group radio" key={index}>
                                <input type="radio" name='take' id={item.value} value={item.value} onChange={() => Q2handler(index)} />
                                <label htmlFor={item.value}>{item.value}</label>
                              </div>
                            ))

                            }


                          </div>
                          <div className="form_title">
                            <span>Extremely often</span>
                          </div>

                          <div className="buttons">
                            <Link
                              className="btn btn-edit previousStep"

                            >
                              Back
                            </Link>
                            <button
                              type="button"
                              className="btn nextStep"

                              disabled={!enteredQ2}
                            >
                              Next
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                {/* <!-- question step 3 --> */}
                <div className="onboarding_modal wizardStep ques_step_modal step-3">
                  <div className="modal_inner">
                    <button
                      type="button"
                      className="btn-close"
                      aria-label="Close"
                      data-bs-dismiss="modal"
                    ></button>
                    {/* <!-- logo --> */}
                    <div className="modal_logo d-none d-md-inline-flex">
                      <img src="./assets/img/logo/modal-logo.png" alt="" />
                    </div>

                    {/* <!-- content --> */}
                    <div className="modal_content">
                      <div className="modal_title">
                        <h4 className="title">Only 5 quick questions</h4>
                        <p className="subtitle">
                          So we can tailor your experience for you.
                        </p>
                        <div className="count">
                          <span>
                            <span className="active">3</span> / 5
                          </span>
                        </div>
                      </div>

                      {/* <!-- form --> */}
                      <div className="form">
                        <div action="#" className="ques_step_form step-2">
                          <div className="form_title">
                            <p>What are your favorite genres to shoot?</p>
                          </div>
                          <div className="checkboxes">

                            {GenresList.map((item, index) => (
                              <div className="col-6" key={index}>
                                <div className="form_group checkbox">
                                  <input className='check' type="checkbox"
                                    name={item.value} value={item.value} id={item.value} onChange={Q3handler} />
                                  <label htmlFor={item.value}>{item.value}</label>
                                </div>
                              </div>
                            ))
                            }

                          </div>
                          <div className="buttons">
                            <Link
                              className="btn btn-edit previousStep"

                            >
                              Back
                            </Link>
                            <button
                              type="button"
                              className="btn nextStep"

                              disabled={!enteredQ3.length > 0}
                            >
                              Next
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                {/* <!-- question step 4 --> */}
                <div className="onboarding_modal wizardStep ques_step_modal step-4">
                  <div className="modal_inner">
                    <button
                      type="button"
                      className="btn-close"
                      aria-label="Close"
                      data-bs-dismiss="modal"
                    ></button>
                    {/* <!-- logo --> */}
                    <div className="modal_logo d-none d-md-inline-flex">
                      <img src="./assets/img/logo/modal-logo.png" alt="" />
                    </div>

                    {/* <!-- content --> */}
                    <div className="modal_content">
                      <div className="modal_title">
                        <h4 className="title">Only 5 quick questions</h4>
                        <p className="subtitle">Only one more after this one.</p>
                        <div className="count">
                          <span>
                            <span className="active">4</span> / 5
                          </span>
                        </div>
                      </div>

                      {/* <!-- form --> */}
                      <div className="form">
                        <div action="#" className="ques_step_form step-4">
                          <div className="form_title">
                            <p>Which camera brand do you prefer?</p>
                          </div>
                          <div className="radios">


                            {cameraTypes.map((item, index) => (
                              <div className="form_group radio" key={index}>
                                <input type="radio" name='camera' id={item.value} value={item.value} onChange={() => Q4handler(index)} />
                                <label htmlFor={item.value}>{item.displayText}</label>
                              </div>
                            ))

                            }

                          </div>

                          <div className="buttons">
                            <Link
                              className="btn btn-edit previousStep"

                            >
                              Back
                            </Link>
                            <button className="btn nextStep" type="button" data-bs-dismiss="modalll" disabled={!enteredQ4}  >
                              Next
                            </button>
                          </div>
                        </div>
                      </div>

                    </div>
                  </div>
                </div>


                {/* question step 5 */}
                <div className="onboarding_modal wizardStep ques_step_modal step-5">
                  <div className="modal_inner">
                    <button type="button" className="btn-close" aria-label="Close" data-bs-dismiss="modal" />
                    {/* logo */}
                    <div className="modal_logo d-none d-md-inline-flex">
                      <img src="./assets/img/logo/modal-logo.png" alt />
                    </div>
                    {/* content */}
                    <div className="modal_content">
                      <div className="modal_title">
                        <h4 className="title">Only 5 quick questions</h4>
                        <p className="subtitle">Last one, then you're in!</p>
                        <div className="count">
                          <span><span className="active">5</span> / 5</span>
                        </div>
                      </div>
                      {/* form */}
                      <div className="form">
                        <div action="#" className="ques_step_form step-5">
                          <div className="form_title">
                            <p>Which of the following is the most important thing that the Big Photo Hunt could help with?</p>
                          </div>
                          <div className="radios">

                            {q5List.map((item, index) => (
                              <div className="form_group radio" key={index}>
                                <input type="radio" name='help' id={item.value} value={item.value} onChange={() => Q5handler(index)} />
                                <label htmlFor={item.value}>{item.value}</label>
                              </div>
                            ))
                            }

                          </div>
                          <div className="buttons">
                            <a className="btn btn-edit previousStep" >Back</a>
                            {/* {!spinner ? */}
                            <button className="btn nextStep" type="button" disabled={!enteredQ5}>Next</button>
                            {/* :
                              <button className="btn">
                                <span class="loader"></span>
                              </button>
                            } */}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>



                {/* user location modal */}
                <div className="onboarding_modal wizardStep ques_step_modal step-6">
                  <div className="modal_inner">
                    <button type="button" className="btn-close" aria-label="Close" data-bs-dismiss="modal" />
                    {/* logo */}
                    <div className="modal_logo d-none d-md-inline-flex">
                      <img src="./assets/img/logo/modal-logo.png" alt />
                    </div>
                    {/* content */}
                    <div className="modal_content">
                      <div className="modal_title">
                        <h4 className="title">Oh, we need to know where you are!</h4>
                        <p className="subtitle">Where in the world do you live?!</p>
                      </div>
                      {/* form */}
                      <div className="form">

                        <div className="form_group">
                          <label htmlFor="form">From</label>

                          <div className="canvas">
                            <PlacesAutocomplete value={address} onChange={handleChange} onSelect={handleSelect}>
                              {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
                                <div>
                                  <input
                                    {...getInputProps({
                                      placeholder: 'City, Country...',
                                      className: 'location-search-input',
                                    })}
                                  />
                                  <div className="autocomplete-dropdown-container">
                                    {loading && <div>Loading...</div>}
                                    {suggestions.map((suggestion) => {
                                      const { description } = suggestion;
                                     
                                      const style = suggestion.active
                                        ? { backgroundColor: '#f2f3f4', cursor: 'pointer' }
                                        : { backgroundColor: '#ffffff', cursor: 'pointer' };
                                      return (
                                        <div
                                          className="input-suggestion"
                                          {...getSuggestionItemProps(suggestion, {
                                            style,
                                          })}
                                        >
                                          <i className="bi bi-geo-alt-fill"></i> <span>{description}</span>
                                        </div>
                                      );
                                    })}
                                  </div>
                                </div>
                              )}
                            </PlacesAutocomplete>
                            {!selectAdress && <p style={{ color: "red" }}>Please select one of the suggested options</p>}
                          </div>

                        </div>
                        <div className="buttons">
                          <button type="button"
                            className={`btn btn-edit ${!skipModalState ? "previousStep" : ""}`}
                            onClick={skipModalState
                              ? () => {
                                document.querySelector(".step-5").classList.remove("show");
                                document.querySelector(".step-1").classList.add("show");
                              }
                              : undefined
                            }

                          >Back</button>
                          {!spinner ?
                            <button
                              className="btn"
                              type={`${!skipModalState ? "submit" : "button"}`}
                              disabled={!selectAdress}
                              onClick={() => { 
                                if (skipModalState) {
                                  document.querySelector('.joinModalClose').click();
                                }
                              }}
                              data-bs-toggle={skipModalState ? "modal" : ""}
                              data-bs-target={skipModalState ? "#skipModal" : ""}
                            >Get started</button>
                            :
                            <button className="btn">
                              <span class="loader"></span>
                            </button>
                          }

                        </div>

                      </div>
                    </div>
                  </div>
                </div>


              </div>
            </div>
          </div>
        </div>
      </form>


      {/* /////////skip modal/////////// */}

      <div
        className="modal fade bph-modal"
        id="skipModal"
        tabIndex={-1}
        aria-labelledby="skipModal"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-body">
              <div className="onboarding_modal skip_modal">
                <div className="modal_inner">
                  <button
                    type="button"
                    className="btn-close skipclose"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                  ></button>
                  {/* <!-- logo --> */}
                  <div className="modal_logo">
                    <img src="./assets/img/logo/modal-logo.png" />
                  </div>

                  {/* <!-- content --> */}
                  <div className="modal_content">
                    <div className="modal_title">
                      <h4 className="title">Hi!</h4>
                    </div>

                    {/* <!-- form --> */}
                    <div className="form">
                      <div className="form_title">
                        <p>
                          Since you’re just here to support our photographers,
                          come right in and make yourself at home!
                        </p>
                      </div>

                      <div className="buttons">
                        {!spinner ?
                          <Link className="btn" to="#" onClick={JoinHandler}> Enter</Link>
                          :
                          <Link className="btn">
                            <span class="loader"></span>
                          </Link>
                        }
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

    </>
  );
};

export default JoinNowModal;

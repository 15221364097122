import axios from 'axios'
import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import { useNavigate } from "react-router-dom"
import { formatDate } from "../../utils/Formatteddate"
import { formatLikes } from "../../utils/FomattedLike"
import CryptoJS from 'crypto-js'
import { decryptUserData } from '../../utils/encryptedData'
import { useDispatch } from "react-redux";
import { ModalAction } from "../../Store/Modal-Slice";
import { ShortAddress } from '../../utils/Address';
import MainImageSkeleton from '../Skeleton/MainImageSkeleton';
import useImageLoader from '../../Hooks/useImageLoader';



const MembersList = (props) => {
  let [upDownvote, setupDownvote] = useState(props.voted || false)
  let [votebtnactive, setvotebtnactive] = useState(props.voted || false)
  let [TotalVote, setTotalVote] = useState(props.TotalVotes)



  let dispatch = useDispatch()

  // <---------------- decrypted data ------------->
  const encryptedUserData = localStorage.getItem('Data');
  const decryptedUserData = decryptUserData(encryptedUserData);
  let usertoken = decryptedUserData.token
  let isLoggedIn = decryptedUserData.isLoggedIn
  let Userid = decryptedUserData.userid
  // <-------------------------------------------->

  let MainImgHandler = () => {
    dispatch(ModalAction.setPopupModalMessage({
      "joinModalHeading": "",
      "popupHeading": "You’re In!",
      "popupMessage": "Click on Continue below to get you back to the photo you were interested in. Check your email too. It's where we'll send you each week's most upvoted photos.",
      photoID: props.photoId,
      userID: props.userId,
    }))
  }

  // <--------------------------->

  let navigate = useNavigate();

  let OtheruserProfileNavigateHandler = (event) => {
    event.preventDefault();



    if (Userid == props.userId) {
      navigate("/profile");
    } else {
      navigate(`/otherusers-profile?photoid=${props.photoId}&userid=${props.userId}`);
    }

    window.scrollTo(0, 0)
  }


  // formatDate function 
  const formattedDate = formatDate(props.postedDate);

  //
  //// Like

  const formattedLikes = formatLikes(TotalVote);

  ///address
  let address = ShortAddress(props.userLocation, 11);

// ImageLoaded hook 
  const isImageLoaded = useImageLoader(props.img);

  useEffect(() => {
    setupDownvote(props.voted || false);
    setvotebtnactive(props.voted || false);

  }, [props.voted]);


  let voteHandler = async (event) => {

    event.preventDefault();
    setvotebtnactive(!votebtnactive)
    setupDownvote(!upDownvote)



    let up_or_down_vote;
    if (upDownvote) {
      up_or_down_vote = "remove"
    } else {
      up_or_down_vote = "add"

    }
    // ......................
    try {
      const payload = {
        "photoID": props.photoId,
        "actionType": up_or_down_vote
      };
      let headers =   {
        'Authorization': `Bearer ${usertoken}`,
        'Content-Type': 'application/json'
      }
      const response = await axios.post(`${process.env.REACT_APP_BASE_URL}/vote/perform`, payload, { headers });

      setTotalVote(response.data.data.voteCount)

    } catch (error) {
      console.error('Error:', error);
      
    }

  }



  return (
    <>

      <div className="member_item">
        <Link to="">
          <div className="member_item_inner">
          {!isImageLoaded ? (
            <MainImageSkeleton />
          ) : (
            <img src={props.img} onClick={isLoggedIn ? OtheruserProfileNavigateHandler : MainImgHandler} data-bs-toggle={`${!isLoggedIn ? "modal" : ""}`} data-bs-target="#joinModal" />
          )}
            

            <div className="member_hover_text">
              <div className="member_vote">
                <Link onClick={isLoggedIn ? voteHandler : () => {
                  dispatch(ModalAction.setPopupModalMessage({
                    "joinModalHeading": "to upvote",
                    "popupHeading": "You’re Upvote is Official!",
                    "popupMessage": "Check your email to confirm you got what we sent you. It's where we'll send you each week's most upvoted photos.",
                    photoID: props.photoId,
                    userID: props.userId,

                  }))
                }} data-bs-toggle={`${!isLoggedIn ? "modal" : ""}`} data-bs-target="#joinModal" className={`btn btn-vote ${votebtnactive ? "active" : ""}`}>
                  <img src="./assets/img/icons/arrow-up.svg" />
                  {formattedLikes}
                </Link>
              </div>
            </div>

            <div className="member_user" onClick={isLoggedIn ? OtheruserProfileNavigateHandler : MainImgHandler} data-bs-toggle={`${!isLoggedIn ? "modal" : ""}`} data-bs-target="#joinModal">
              <Link
                className='memberUser_img'
                style={{
                  backgroundImage: `url(${props.userPhoto || ""})`,
                  backgroundPosition: "center",
                  backgroundSize: "cover",
                  backgroundRepeat: "no-repeat",
                  borderRadius: "50%",
                  cursor: "pointer",
                }}
              >
                {!props.userPhoto && <img src={`https://ui-avatars.com/api/?name=${props.Name}&size=40&rounded=true&background=01655a&color=fff`} />}
              </Link>

              <div className="user_text">
                <Link to="#" className="user_name" >
                  {props.Name}
                </Link>
                <p className="user_meta">
                  {address && <span className="location">{address}</span>}
                  <span className="date">{formattedDate}</span>
                </p>
              </div>
            </div>
          </div>
        </Link>
      </div>

    </>
  )
}

export default MembersList
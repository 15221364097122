import React from 'react'
import { useState } from "react";
import { useEffect, useRef } from "react";
import axios from "axios";
import ProfileInfo from "../Components/Profile/ProfileInfo"
import ProfileMyPhotoFeedList from '../Components/Profile/ProfileMyPhotoFeedList';
import ProfileNoPhoto from '../Components/Profile/NoPhotoSection'
// import ProfileUpvotesPhoto from './ProfileUpvotesPhoto';
import { Link } from 'react-router-dom';
import { useSelector } from "react-redux";
import ProfileUpvotesPhotolist from '../Components/Profile/ProfileUpvotesPhotolist'
import { decryptUserData } from '../utils/encryptedData'
import ReactGA from "react-ga"



let increment = 0;
const ProfilePage = () => {
    let [MyPhotoState, setMyPhotoState] = useState(true)
    let [UpvotePhotoState, setUpvotePhotoState] = useState(false)
    let footerr = useRef();
    let [firstloadPhoto, setfirstloadPhoto] = useState([])
    let [loadPhoto, setloadPhoto] = useState([])
    let [spinner, setspinner] = useState(false)
    let [CheckEmptyArry, setCheckEmptyArry] = useState(true)
    let [FilterValue, setFilterValue] = useState('')
    let [checkingPhotoLength, setcheckingPhotoLength] = useState([])


    let AfterUploadPhoto = useSelector(state => state.Modal.StateAfterUploadPhoto)
    

    let MyPhotoHndler = () => {
        setMyPhotoState(true);
        setUpvotePhotoState(false)
    }

    let UpvotePhotoHandler = () => {
        setMyPhotoState(false);
        setUpvotePhotoState(true)
    }


    // <---------------- decrypted data ------------->
    const encryptedUserData = localStorage.getItem('Data');
    const decryptedUserData = decryptUserData(encryptedUserData);
    let usertoken = decryptedUserData.token
    
    // <-------------------------------------------->
    //////////

    function AddLibrary(urlOfTheLibrary) {
        const script = document.createElement("script");
        script.src = urlOfTheLibrary;
        script.async = true;
        document.body.appendChild(script);
    }

    //..............................

    let LeaderboardHandler = () => {
        setFilterValue("leader_board")
        increment = 0
        setCheckEmptyArry(true)
        // setfirstloadPhoto([])
        // setloadPhoto([])
    }
    let MostRecentHandler = () => {
        setFilterValue("by_recent_photos")
        increment = 0
        setCheckEmptyArry(true)
        // setfirstloadPhoto([])
        // setloadPhoto([])
    }
    // ..............................
    
    useEffect(()=>{
        ReactGA.pageview(window.location.pathname + window.location.search);
      },[])


    //......................

    let apiCall = (e) => {
        if (e[0].isIntersecting) {
            increment = increment + 10;
           
            let LoadPhotos = async function () {

                setspinner(true)
               
                // ......................
                try {
                    const payload = {
                        "userType": "by_user",
                        "sortingType": FilterValue || "by_recent_photos",
                        "offSet": increment
                    };
                    let headers = {
                        'Authorization': `Bearer ${usertoken}`,
                        'Content-Type': 'application/json'
                    }

                    const url = !UpvotePhotoState
                        ? `${process.env.REACT_APP_BASE_URL}/photo/list`
                        : `${process.env.REACT_APP_BASE_URL}/photo/list_upvoted_photo`
                    
                    const response = await axios.post(url, payload, { headers });

                    const newPhotos = response.data.data;
                    setloadPhoto(prevPhotos => [...prevPhotos, ...newPhotos])

                    if (response.data.data.length == 0 || response.data.data.length < 10) {
                        setCheckEmptyArry(false)
                    }
                    
                    setspinner(false)

                   
                } catch (error) {
                    console.error('Error:', error);

                   
                }
            }
            LoadPhotos();
        }


    }


    


    ///////////

    useEffect(() => {

        const Observer = new IntersectionObserver(apiCall, {
            root: null,
            threshold: 0

        })
       
        if (footerr.current) Observer.observe(footerr.current)
        return () => {
            if (footerr.current) Observer.unobserve(footerr.current);
        };
    }, [FilterValue, firstloadPhoto])
    ////////////


    // .................. checking user upload or upvote photo or not.................

    

    useEffect(() => {
        setfirstloadPhoto([])
        setloadPhoto([])
        let LoadPhotos = async function () {


            
            // ......................
            setspinner(true)
            try {
                const payload = {
                    "userType": "by_user",
                    "sortingType": FilterValue || "by_recent_photos",
                    "offSet": 0
                };
                let headers = {
                    'Authorization': `Bearer ${usertoken}`,
                    'Content-Type': 'application/json'
                }

                const url = !UpvotePhotoState
                        ? `${process.env.REACT_APP_BASE_URL}/photo/list`
                        : `${process.env.REACT_APP_BASE_URL}/photo/list_upvoted_photo`
                    
                    const response = await axios.post(url, payload, { headers });

                
                setfirstloadPhoto(response.data.data)
                if (response.data.data.length < 10) {
                    setCheckEmptyArry(false)
                }else{
                    setCheckEmptyArry(true)
                }
                setspinner(false)
               
                increment = 0

            } catch (error) {
                console.error('Error:', error.response.data.message);
                       }
            // .............

        }
        LoadPhotos();
    }, [FilterValue, AfterUploadPhoto, UpvotePhotoState])

    useEffect(() => {

        
        let LoadPhotoCount = async function () {

           
            let config = {
                method: 'post',

                url: `${process.env.REACT_APP_BASE_URL}/photo/get_photos_count`,
                headers: {
                    'Authorization': `Bearer ${usertoken}`
                }
            };

            axios.request(config)
                .then((response) => {
                    const data = response.data.data;
               
                    setcheckingPhotoLength(data[0])
                })
                .catch((error) => {
                    console.log(error);
                });


        }
        LoadPhotoCount();
    }, [AfterUploadPhoto])

    

    let AllloadPhoto;
    if (increment === 0) {
        AllloadPhoto = [...firstloadPhoto]
    } else {

        AllloadPhoto = [...firstloadPhoto, ...loadPhoto]

    }

  
    return (
        <>
            <ProfileInfo />

            <section className="photo-feed-section my-profile">
                <div className="container">
                    <div className="row">
                        <div className="col-md-9 col-lg-8">

                            {(checkingPhotoLength.photo_count > 0) || (checkingPhotoLength.upvote_count > 0) ?
                                <div className="feed_content">
                                    <ul className="heading_tabs" role="tablist">
                                        <li className="active" role="presentation" data-bs-toggle="tab" data-bs-target="#profile" aria-selected="true" onClick={MyPhotoHndler}
                                        // onClick={MyPhotoHndler}
                                        >
                                            My Photos
                                        </li>
                                        <li role="presentation" data-bs-toggle="tab" data-bs-target="#upvotes" aria-selected="false" tabIndex={-1} onClick={UpvotePhotoHandler}
                                        // onClick={UpvotesHndler}
                                        >
                                           My Upvotes
                                        </li>
                                    </ul>
                                    <div className="tab-content" id="profileTabContent">

                                        {MyPhotoState && <div className="" id="profile" role="tabpanel">
                                            <div className="feed_content_heading d-flex flex-column flex-lg-row align-items-center justify-content-center justify-content-lg-between flex-wrap">
                                                <h3 className="title">My Photos</h3>
                                                <ul className="feedFilter dropdown">
                                                    <li className="activated">Most Recent </li>
                                                    <img src="./assets/img/icons/arrow-down.svg" alt />
                                                    <ul className="filter_lists">
                                                        <li className={`${FilterValue !== "leader_board" ? "active" : ""}`}>
                                                            <Link onClick={MostRecentHandler}>Most Recent </Link>
                                                        </li>

                                                        <li className={`${FilterValue == "leader_board" ? "active" : ""}`}>
                                                            <Link onClick={LeaderboardHandler}>By Upvotes</Link>
                                                        </li>
                                                        {/* <li><Link  >New Members</Link></li>
                                                    <li>
                                                        <Link  >Last Month’s Winners</Link>
                                                    </li> */}
                                                    </ul>
                                                </ul>
                                            </div>
                                            <div className="feed_items text-center text-md-start">

                                                {AllloadPhoto.map((data) => (<ProfileMyPhotoFeedList

                                                    img={data.photo_url}
                                                    postId={data.id}
                                                    key={data.id}
                                                    TotalVotes={data.total_votes}
                                                    voted={data.is_vote}
                                                    Name={data.name}
                                                    postedDate={data.photo_posted_date}
                                                    TotalComments={data.total_comments}
                                                    First5CommentsPhotoList={data.commenters_info}
                                                    Userlocation={data.state}
                                                    First3Comments = {data.comments}

                                                />))
                                                }

                                            </div>
                                        </div>}
                                        {/* .................... */}

                                        {UpvotePhotoState && <div className="" id="upvotes" role="tabpanel">
                                            <div className="feed_content_heading d-flex flex-column flex-lg-row align-items-center justify-content-center justify-content-lg-between flex-wrap">
                                                <h3 className="title">My Upvotes</h3>
                                                <ul className="feedFilter dropdown">
                                                    <li className="activated">Most Recent </li>
                                                    <img src="./assets/img/icons/arrow-down.svg" alt />
                                                    <ul className="filter_lists">
                                                        <li className="active">
                                                            <Link onClick={MostRecentHandler}>Most Recent </Link>
                                                        </li>
                                                        <li><Link onClick={LeaderboardHandler}>By Upvotes</Link></li>
                                                        {/* <li><a  >New Members</a></li>
                                                    <li>
                                                        <a  >Last Month’s Winners</a>
                                                    </li> */}
                                                    </ul>
                                                </ul>
                                            </div>
                                            <div className="feed_items text-center text-md-start">

                                                {AllloadPhoto.map((data) => (<ProfileUpvotesPhotolist

                                                    img={data.photo_url}
                                                    postId={data.photo_id}
                                                    key={data.id}
                                                    TotalVotes={data.total_votes}
                                                    voted={data.is_vote}
                                                    Name={data.name}
                                                    postedDate={data.photo_posted_date}
                                                    TotalComments={data.total_comments}
                                                    Userlocation={data.state}
                                                    UserPhoto={data.user_photo_url}
                                                    Userid={data.user_id}
                                                />))
                                                }

                                            </div>
                                        </div>}

                                        {CheckEmptyArry && spinner && <div style={{ display: "flex", justifyContent: "center" }}>
                                            <div className="spinner-border text-secondary" role="status">
                                                <span className="sr-only"></span>
                                            </div>
                                        </div>}

                                    </div>
                                </div>

                                : <ProfileNoPhoto
                                />
                            }


                        </div>


                    </div>
                </div>
            </section>






            {CheckEmptyArry && AllloadPhoto.length > 0 && <div ref={footerr}></div>}


            {AddLibrary("./assets/js/magnific-popup.min.js")}
            {AddLibrary("./assets/js/owl.carousel.min.js")}
            {AddLibrary("./assets/js/main.js")}
        </>
    )
}

export default ProfilePage
import axios from 'axios'
import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { useLocation } from "react-router-dom";
import { decryptUserData } from '../../utils/encryptedData'
import { useDispatch } from 'react-redux';
import { ModalAction } from '../../Store/Modal-Slice';
import { ShortAddress } from '../../utils/Address';
import ProfileInfoSkeleton from '../Skeleton/ProfileInfoSkeleton';





const OtherUserProfileInfo = () => {
  let [defaultUserInfo, setdefaultUserInfo] = useState('')
  let [GenresValue, setGenresValue] = useState('')
  let [followState, setfollowState] = useState('')
  let [Followers, setFollowers] = useState('')
  let [Following, setFollowing] = useState('')


  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);

  let UserId = searchParams.get('userid');

  let address
  if (defaultUserInfo.state) {
    address = ShortAddress(defaultUserInfo.state, 21);
  }

  // <---------------- decrypted data ------------->
  const encryptedUserData = localStorage.getItem('Data');
  const decryptedUserData = decryptUserData(encryptedUserData);
  let usertoken = decryptedUserData.token
  // <-------------------------------------------->

  let dispatch = useDispatch()


  // <-------------------------------------------->


  function removeProtocolFromURL(url) {
    if (!url) {
      return "";
    }
    const protocolRegex = /^(https?:\/\/)/i;
    return url.replace(protocolRegex, '');
  }

  const modifiedURL = removeProtocolFromURL(defaultUserInfo.website);


  //changing date format
  const dateString = defaultUserInfo.createdAt;
  const date = new Date(dateString);

  const options = { day: '2-digit', month: '2-digit', year: 'numeric' };
  const formattedDate = date.toLocaleDateString('en-US', options);
  //


  const genresString = GenresValue ? Object.values(GenresValue).join(", ") : "";
  useEffect(() => {

    let otherUserinfo = async function () {



      try {
        const payload = {
          "userID": UserId,
        };
        let headers = {
          'Authorization': `Bearer ${usertoken}`,
          'Content-Type': 'application/json'
        }
        const response = await axios.post(`${process.env.REACT_APP_BASE_URL}/user/get_user_profile`, payload, { headers });

        setdefaultUserInfo(response.data.data[0])
        setfollowState(response.data.data[0].is_follow)
        setGenresValue(response.data.data[0].genres_list)
        setFollowers(response.data.data[0].Followers)
        setFollowing(response.data.data[0].Following)
      } catch (error) {
        console.error(error);


      }
    }
    otherUserinfo();

  }, [UserId])

  let FollowHandler = async (event) => {
    event.preventDefault();


    setfollowState(!followState)


    try {
      const payload = {
        "followerID": UserId,
        "is_follow": !followState
      };
      let headers = {
        'Authorization': `Bearer ${usertoken}`,
        'Content-Type': 'application/json'
      }
      const response = await axios.post(`${process.env.REACT_APP_BASE_URL}/follow/user`, payload, { headers });




     } catch (error) {
      console.error(error);
     }

  }


  return (
    <>
      <main className="site-content" id="main"  >
        {!defaultUserInfo ? <ProfileInfoSkeleton /> :
          <section className="profile-section">
            <div className="container">
              <div className="row">
                <div className="col-lg-6">
                  <div className="profile_info">
                    <div className="profile_info_inner">
                      <div className="profile_img"
                        style={{
                          backgroundImage: `url(${defaultUserInfo.photo_url || ""})`,
                          backgroundPosition: "center",
                          backgroundSize: "cover",
                          backgroundRepeat: "no-repeat",
                          borderRadius: "50%",
                          cursor: "pointer",
                        }}
                      >
                        {!defaultUserInfo.photo_url && <img src={`https://ui-avatars.com/api/?name=${defaultUserInfo.name}&size=40&rounded=true&background=01655a&color=fff`} />}
                      </div>
                      <div className="profile_info_text">
                        <div className="profile_name">
                          <h4 className="name">{defaultUserInfo.name}</h4>
                        </div>

                        <div className="profile_contact">

                          <ul className="socials">
                            {defaultUserInfo.facebook_link && <li>
                              <Link to={`https://www.facebook.com/${defaultUserInfo.facebook_link}`} target="_blank">
                                <img src="./assets/img/icons/facebook.svg" alt="" />
                              </Link>
                            </li>}
                            {defaultUserInfo.twitter_link && <li>
                              <Link to={`https://twitter.com/${defaultUserInfo.twitter_link}`} target="_blank">
                                <img src="./assets/img/icons/twitter.svg" alt="" />
                              </Link>
                            </li>}
                            {defaultUserInfo.instagram_link && <li>
                              <Link to={`https://www.instagram.com/${defaultUserInfo.instagram_link}`} target="_blank">
                                <img src="./assets/img/icons/instagram.svg" alt="" />
                              </Link>
                            </li>}
                          </ul>
                          {defaultUserInfo.website &&
                            <div className="website">
                              <Link to={defaultUserInfo.website} target="_blank">
                                {modifiedURL}
                              </Link>
                            </div>}
                        </div>

                        <div className="connected_with d-none d-md-flex">
                          <div class="following" >
                            <span class="title">Following</span>
                            <div class="following_users">
                              <Link to={'/follow'} onClick={() => { window.scroll(0, 0) }}>
                                <ul class="users">
                                  <li>
                                    <a href="#"><img src="./assets/img/users/user-1.png" alt="" /></a>
                                  </li>
                                  <li>
                                    <a href="#"><img src="./assets/img/users/user-2.png" alt="" /></a>
                                  </li>
                                  <li>
                                    <a href="#"><img src="./assets/img/users/user-3.png" alt="" /></a>
                                  </li>
                                </ul>
                              </Link>
                              <span class="count">+152</span>
                            </div>
                          </div>

                          <div class="followers" >
                            <span class="title">Followers</span>
                            <div class="follower_users">
                              <Link to={'/follow'} onClick={() => { window.scroll(0, 0) }}>
                                <ul class="users">
                                  <li>
                                    <a href="#"><img src="./assets/img/users/user-1.png" alt="" /></a>
                                  </li>
                                  <li>
                                    <a href="#"><img src="./assets/img/users/user-2.png" alt="" /></a>
                                  </li>
                                  <li>
                                    <a href="#"><img src="./assets/img/users/user-3.png" alt="" /></a>
                                  </li>
                                </ul>
                              </Link>
                              <span class="count">+502</span>
                            </div>
                          </div>
                          {/* <div className="following">
                          <span className="title">Following</span>
                          <div className="following_users">
                          {(Following.count > 0) && <ul className="users">
                              {Following.data && Following.data.map((url, index) => (
                                <li key={index} >
                                    <Link to=""  
                                    style={{
                                      backgroundImage: `url(${url.photo_url || ""})`,
                                      backgroundPosition: "center",
                                      backgroundSize: "cover",
                                      backgroundRepeat: "no-repeat",
                                      borderRadius: "50%",
                                      cursor: "pointer",
              
                                    }}>
                                       {!url.photo_url && <img  style={{ borderRadius: '50%',height:'100%',width:'auto' }} 
                                       src={`https://ui-avatars.com/api/?name=${url.name}&size=40&rounded=true&background=01655a&color=fff`} alt="ddd" />}
                                    </Link>
                                </li>
                            ))

                            } 
                            </ul>}
                            <span className="count">{Following.count}</span>
                          </div>
                        </div> */}
                          {/* <div className="followers">
                          <span className="title">Followers</span>
                          <div className="follower_users">
                          {(Followers.count > 0) && <ul className="users">
                              {Followers.data && Followers.data.map((url, index) => (
                                <li key={index} >
                                    <Link to=""  
                                    style={{
                                      backgroundImage: `url(${url.photo_url || ""})`,
                                      backgroundPosition: "center",
                                      backgroundSize: "cover",
                                      backgroundRepeat: "no-repeat",
                                      borderRadius: "50%",
                                      cursor: "pointer",
              
                                    }}>
                                       {!url.photo_url && <img  style={{ borderRadius: '50%',height:'100%',width:'auto' }} 
                                       src={ `https://ui-avatars.com/api/?name=${url.name}&size=40&rounded=true&background=01655a&color=fff`} alt="ddd" />}
                                    </Link>
                                </li>
                            ))} 
                            </ul>}
                            <span className="count">{Followers.count}</span>
                          </div>
                        </div> */}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="profile_details">
                    <div className="profile_details_inner">
                      <div className="details">
                        {defaultUserInfo.state && <div className="details_item from">
                          <span>From:</span>
                          <p>{address}</p>
                        </div>}
                        {(defaultUserInfo.camera_brand && defaultUserInfo.camera_brand !== "null") && <div className="details_item camera">
                          <span>Camera System:</span>
                          <p>{defaultUserInfo.camera_brand}</p>
                        </div>}
                        <div className="details_item since">
                          <span>Member since:</span>
                          <p>{formattedDate}</p>
                        </div>
                      </div>
                      <div className="details">
                        {(defaultUserInfo.photo_assistance && defaultUserInfo.photo_assistance !== "null") && <div className="details_item why">
                          <span>Why I'm Here:</span>
                          <p>{defaultUserInfo.photo_assistance}</p>
                        </div>}
                        {genresString && <div className="details_item genres">
                          <span>Genres:</span>
                          <p>{genresString}</p>
                        </div>}
                      </div>
                      <div className="profile_edit d-none d-md-block">
                        {!followState ? <Link className="btn btn-edit follow" onClick={FollowHandler}>
                          Follow <img src="./assets/img/icons/user-plus.svg" />
                        </Link> : <Link className="btn btn-edit following" onClick={FollowHandler}>Following
                          <img src="./assets/img/icons/user-minus.svg" /></Link>}
                      </div>
                    </div>

                    {/* <!-- mobile connected with --> */}
                    <div className="connected_with d-md-none">
                      <div class="following">
                        <span class="title">Following</span>
                        <div class="following_users">
                          <Link to={'/follow'} onClick={() => { window.scroll(0, 0) }}>
                            <ul class="users">
                              <li>
                                <a href="#"><img src="./assets/img/users/user-1.png" alt="" /></a>
                              </li>
                              <li>
                                <a href="#"><img src="./assets/img/users/user-2.png" alt="" /></a>
                              </li>
                              <li>
                                <a href="#"><img src="./assets/img/users/user-3.png" alt="" /></a>
                              </li>
                            </ul>
                          </Link>
                          <span class="count">+152</span>
                        </div>
                      </div>

                      <div class="followers" >
                        <span class="title">Followers</span>
                        <div class="follower_users">
                          <Link to={'/follow'} onClick={() => { window.scroll(0, 0) }}>
                            <ul class="users">
                              <li>
                                <a href="#"><img src="./assets/img/users/user-1.png" alt="" /></a>
                              </li>
                              <li>
                                <a href="#"><img src="./assets/img/users/user-2.png" alt="" /></a>
                              </li>
                              <li>
                                <a href="#"><img src="./assets/img/users/user-3.png" alt="" /></a>
                              </li>
                            </ul>
                          </Link>
                          <span class="count">+502</span>
                        </div>
                      </div>
                      {/* <div className="following">
                      <span className="title">Following</span>
                      <div className="following_users">
                      {(Following.count > 0) && <ul className="users">
                              {Following.data && Following.data.map((url, index) => (
                                <li key={index} >
                                    <Link to=""  
                                    style={{
                                      backgroundImage: `url(${url.photo_url || ""})`,
                                      backgroundPosition: "center",
                                      backgroundSize: "cover",
                                      backgroundRepeat: "no-repeat",
                                      borderRadius: "50%",
                                      cursor: "pointer",
              
                                    }}>
                                       {!url.photo_url && <img  style={{ borderRadius: '50%',height:'100%',width:'auto' }} 
                                       src={ `https://ui-avatars.com/api/?name=${url.name}&size=40&rounded=true&background=01655a&color=fff`} alt="ddd" />}
                                    </Link>
                                </li>
                            ))

                            } 
                            </ul>}
                        <span className="count">{Followers.count}</span>
                      </div>
                    </div> */}
                      {/* <div className="followers">
                      <span className="title">Followers</span>
                      <div className="follower_users">
                      {(Followers.count > 0) && <ul className="users">
                              {Followers.data && Followers.data.map((url, index) => (
                                <li key={index} >
                                    <Link to=""  
                                    style={{
                                      backgroundImage: `url(${url.photo_url || ""})`,
                                      backgroundPosition: "center",
                                      backgroundSize: "cover",
                                      backgroundRepeat: "no-repeat",
                                      borderRadius: "50%",
                                      cursor: "pointer",
              
                                    }}>
                                       {!url.photo_url && <img  style={{ borderRadius: '50%',height:'100%',width:'auto' }} 
                                       src={`https://ui-avatars.com/api/?name=${url.name}&size=40&rounded=true&background=01655a&color=fff`} alt="ddd" />}
                                    </Link>
                                </li>
                            ))} 
                            </ul>}
                        <span className="count">{Following.count}</span>
                      </div>
                    </div> */}
                    </div>

                    {/* <!-- mobile edit btn --> */}
                    <div className="profile_edit d-md-none">
                      {!followState ? <Link className="btn btn-edit follow" onClick={FollowHandler}>
                        Follow <img src="./assets/img/icons/user-plus.svg" />
                      </Link> : <Link className="btn btn-edit following" onClick={FollowHandler}>Following
                        <img src="./assets/img/icons/user-minus.svg" /></Link>}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>}
      </main>
    </>
  )
}

export default OtherUserProfileInfo
import React from "react";
import { Link } from "react-router-dom";
import { useState } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { ModalAction } from "../../Store/Modal-Slice";
import { decryptUserData } from '../../utils/encryptedData'
import { useSelector } from "react-redux";



const PhotoUploadModal = () => {
  let [image, setimage] = useState('')
  let [spinner, setspinner] = useState(false)
  let [imagePath, setimagePath] = useState('')
  let [ERROR, setERROR] = useState('')
  const [uploaded, setUploaded] = useState(null);

  console.log(uploaded,".......")

  const MAX_FILE_SIZE = 200 * 1024 * 1024; // 200 MB
  const MAX_IMAGE_EDGE = 5000;
  const ALLOWED_FORMATS = ['image/jpeg', 'image/png'];

  let navigate = useNavigate();

  let dispatch = useDispatch()

  let StateAfterUploadPhoto = useSelector(state => state.Modal.StateAfterUploadPhoto)

  // <---------------- decrypted data ------------->
  const encryptedUserData = localStorage.getItem('Data');
  const decryptedUserData = decryptUserData(encryptedUserData);
  let usertoken = decryptedUserData.token


  // <-------------------------------------------->


  const imgHandler = (e) => {
    const selectedImage = e.target.files[0];
    setERROR("")
    // Validate image format
    if (!ALLOWED_FORMATS.includes(selectedImage.type)) {
      setERROR('Please upload only JPG or PNG files.');
      return;
    }

    const img = new Image();
    img.src = URL.createObjectURL(selectedImage);
    img.onload = async () => {
      await img.decode(); // Load the image to get its dimensions

      const { width, height } = img;



      // Validate file size
      if (selectedImage.size > MAX_FILE_SIZE) {
        setERROR('Please ensure that your file is less than 200MB.');
        return;
      } else if (width > MAX_IMAGE_EDGE || height > MAX_IMAGE_EDGE) {
        setERROR('Please ensure the edges of your photo are less than 5000 pixels.');
        return;
      }
      else {
        setimage(selectedImage);

        const reader = new FileReader();
        reader.onload = () => {
          setimagePath(reader.result);
        };
        reader.readAsDataURL(selectedImage);
      };
    }

  };



  const uploadHandler = async (e) => {

    let formData = new FormData()
    formData.append('photoUrl', image)
    // formData.append('user_id', userid)



    if (!image) {
      alert('Plz select image ')
      return
    }

    setspinner(true)
    try {


      let headers = {
        'Authorization': `Bearer ${usertoken}`
      }
      const response = await axios.post(`${process.env.REACT_APP_BASE_URL}/photo/add`, formData, {
        headers,
        onUploadProgress: (data) => {
          setUploaded(Math.round((data.loaded / data.total) * 100));
        }});

      setimagePath('');
      document.querySelector('.close').click();
      navigate('/profile', { replace: true })
      dispatch(ModalAction.setStateAfterUploadPhoto(!StateAfterUploadPhoto))
      window.scrollTo(0, 0)



      setspinner(false)
     } catch (error) {
      console.error('Error:', error);
     }


  }


  return (
    <>
      <div className="backdrop" />
      {/* Photo Upload */}
      <div className="modal fade bph-modal show" id="photoUploadModal" tabIndex={-1} aria-labelledby="photoUploadModal" aria-hidden="true" style={{ display: 'block' }}>
        <div className="modal-dialog modal-dialog-centered modalAnimation">
          <div className="modal-content">
            <div className="modal-body">
              <div className="onboarding_modal photoUpload_modal">
                <div className="modal_inner">
                  <button type="button" className="btn-close close" data-bs-dismiss="modal" aria-label="Close"
                    onClick={() => { dispatch(ModalAction.setPhotoUploadModalState(false)) }} />
                  {/* logo */}
                  <div className="modal_logo d-none d-md-inline-flex">
                    <img src="./assets/img/logo/modal-logo.png" />
                  </div>
                  {/* content */}
                  <div className="modal_content">
                    <div className={`modal_title ${imagePath ? "margin-b" : ''}`}>
                      <h2 className="title"><img src="./assets/img/icons/upload.svg" />Upload</h2>
                      <p className="subtitle">{imagePath ? "Great! We got it. Click below to continue." : "Then get your photo upvoted to the top of the leaderboard."}</p>
                    </div>
                    {/* upload photo */}
                    <div className="file-upload">
                      {ERROR && <p className="eerror" style={{ textAlign: 'center' }}>{ERROR} </p>}
                      {!imagePath && <div className="image-upload-wrap">

                        <input
                          className="file-upload-input"
                          onChange={imgHandler} type="file"
                        // accept=".jpg, .png"
                        />
                        <div className="drag-text">
                          <h4 className="title">Drag and drop your photo here</h4>
                          <span className="or">or</span>
                          <button className="file-upload-btn btn" type="button" >Upload from device</button>
                          <span className="support_note">Max. 5000 x 5000 px., 200 megapixel, JPG or PNG format only.</span>
                        </div>
                      </div>}
                      {imagePath && <div className="file-upload-content">
                        <img className="file-upload-image" src={imagePath} alt="your image" />
                        <div className="image-title-wrap">
                          {!spinner ?
                            <div>
                              <button onClick={uploadHandler} className=" btn" > Continue</button>
                              <label className="remove-profile-image" style={{ marginTop: '10px' }} onClick={() => { setimagePath("") }}>Select a different photo</label>
                            </div>
                            : <div className="spinner-border text-warning" role="status">
                              <span className="sr-only"></span>
                            </div>

// {/* <div className="progress">
//   <div className="progress-bar" role="progressbar" style={{ width: `${uploaded}%` }} aria-valuenow={uploaded} aria-valuemin={0} aria-valuemax={100}>{`${uploaded}%`}</div>
// </div> */}

                          }
                          {/* <button type="button" className="remove-image">Remove <span className="image-title">Image</span></button> */}
                        </div>
                      </div>}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* ! Photo Upload */}

    </>
  )
}

export default PhotoUploadModal
import React, { useState, useEffect, useContext } from "react";
import { Link } from "react-router-dom";
import axios, { AxiosError } from "axios";
import { useNavigate } from "react-router-dom";
import { useFormik } from 'formik';
import { loginSchemas } from '../../schemas';
import { LoginSocialFacebook, LoginSocialGoogle } from 'reactjs-social-login';
import { FacebookLoginButton, GoogleLoginButton } from 'react-social-login-buttons';
import { SocialMediaAction } from "../../Store/SocialMedia-Slice";
import { ModalAction } from "../../Store/Modal-Slice";
import { useDispatch } from "react-redux";
import { LoginAction } from "../../Store/Login-Slice";
import { useSelector } from "react-redux";
import { enc, AES } from 'crypto-js';



let initialValues = {
  email: '',
  password: ''

}



const SignInModal = (props) => {
  let [spinner, setspinner] = useState(false)
  let [error, seterror] = useState(false)

  let navigate = useNavigate();

  let dispatch = useDispatch()
  let UserSocialInfo = useSelector(state => state.SocialMedia.SocialMediaUserInfo)


  let popupData = useSelector(state => state.Modal.PopupModalMessage)
  //////////////////////////////////////////////////////////////////////////////

  useEffect(() => {
    if (!UserSocialInfo[1]) {
      return
    }

    let me = async function () {

      try {
        const payload = {
          "email": UserSocialInfo[1],
        };

        const response = await axios.post(`${process.env.REACT_APP_BASE_URL}/auth/Me`, payload);



        let user_type = response.data.data[0].user_type
        let token = response.data.data[1].token



        if (token && user_type == UserSocialInfo[0]) {
          const userData = {
            userid: response.data.data[0].id,
            useDisplay_name: response.data.data[0].name,
            user_email: response.data.data[0].email,
            user_type: response.data.data[0].user_type,
            token: token,
            Display_UserSocailPhoto: response.data.data[0].photo_url,
            isLoggedIn: true
          };
          const secretKey = 'siddiqui';
          const encryptedUserData = AES.encrypt(JSON.stringify(userData), secretKey).toString();
          localStorage.setItem('Data', encryptedUserData);


          dispatch(LoginAction.setIsLoggedIn(true))
          if (!localStorage.getItem("source") && !popupData.photoID) {
            navigate('/', { replace: true })

          } else if (userData.userid !== popupData.userID && !localStorage.getItem("source")) {
            navigate(`/otherusers-profile?photoid=${popupData.photoID}&userid=${popupData.userID}`);

          } else if (userData.userid == popupData.userID && !localStorage.getItem("source")) {
            navigate('/profile', { replace: true })
            dispatch(ModalAction.setPopupModalMessage({
              "joinModalHeading": "",
            }))
          }

          window.scrollTo(0, 0);
          document.querySelector('.signInModalClose').click();
          document.querySelector('.joinModalClose').click();
          document.querySelector('.ModalClose').click();
          document.querySelector('.skipclose').click();

        } else {
          alert("User already Exist")
        }

         } catch (error) {
        console.error('Error:', error.response.data.message);

        if (error.response.data.message == "User Not Exist") {
          if (document.getElementById('joinModal').style.display != "block") {
            document.getElementById('applee').click();
          }

          dispatch(ModalAction.setJoinModalBackbtn(true))

          let joinAlldiv = document.querySelectorAll(".wizardStep")
          let joinstep1 = document.querySelector(".step-1")
          joinAlldiv.forEach((div) => {
            div.classList.remove("show")
          })
          joinstep1.classList.add("show")
        }

      }
    }
    me();
  }, [UserSocialInfo])

  //////////////////////////////////////////////////////////////////////////////


  const { values, errors, touched, handleBlur, handleChange, handleSubmit, setFieldTouched } = useFormik({
    initialValues: initialValues,
    validationSchema: loginSchemas,
    validateOnBlur: false, // Disable onBlur validation

    onSubmit: async (value, action) => {
      let userEmail = value.email;
      let lowercaseEmail = userEmail.toLowerCase()
      let userPassword = value.password;
      setspinner(true)
      try {
        const payload = {
          // Data to be sent in the POST request
          emailOrUsername: lowercaseEmail,
          password: userPassword,
        };

        const response = await axios.post(`${process.env.REACT_APP_BASE_URL}/auth/login`, payload);

        let user_type = response.data.data[0].user_type
        let token = response.data.data[1].token
        setspinner(false)
        if (token && user_type == "email") {

          const userData = {
            userid: response.data.data[0].id,
            useDisplay_name: response.data.data[0].name,
            user_email: response.data.data[0].email,
            user_type: response.data.data[0].user_type,
            token: token,
            Display_UserSocailPhoto: response.data.data[0].photo_url,
            CreatedAt: response.data.data[0].createdAt,
            isLoggedIn: true
          };
          const secretKey = 'siddiqui';
          const encryptedUserData = AES.encrypt(JSON.stringify(userData), secretKey).toString();
          localStorage.setItem('Data', encryptedUserData);

          dispatch(LoginAction.setIsLoggedIn(true))

          if (!localStorage.getItem("source") && !popupData.photoID) {
            navigate('/', { replace: true })

          } else if (userData.userid !== popupData.userID && !localStorage.getItem("source")) {
            navigate(`/otherusers-profile?photoid=${popupData.photoID}&userid=${popupData.userID}`);

          } else if (userData.userid == popupData.userID && !localStorage.getItem("source")) {
            navigate('/profile', { replace: true })
            dispatch(ModalAction.setPopupModalMessage({
              "joinModalHeading": "",
            }))
          }

          window.scrollTo(0, 0);
          document.querySelector('.signInModalClose').click();

        } else {
          document.querySelector('.google').click()
          document.querySelector('.signInModalClose').click();
        }
        seterror(false)
        action.resetForm();

      } catch (error) {
        let message = error.response.data.message;
        seterror(message);
        setspinner(false)

      }

    }
  })


  return (
    <>

      <div
        className="modal fade bph-modal "
        id="signInModal"
        tabIndex={-1}
        aria-labelledby="signInModal"
        aria-hidden="true"


      >
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-body">
              <div className="onboarding_modal signIn_modal">
                <div className="modal_inner">
                  <button
                    type="button"
                    className="btn-close signInModalClose"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                  // onClick={modalCloseHandler}
                  ></button>
                  {/* <!-- logo --> */}
                  <div className="modal_logo d-none d-md-inline-flex">
                    <img src="./assets/img/logo/modal-logo.png" alt="" />
                  </div>

                  {/* <!-- content --> */}
                  <div className="modal_content">
                    <div className="modal_title">
                      <h4 className="title">Sign In</h4>
                    </div>

                    {/* <!-- log reg button --> */}
                    <div className="log_reg_btn">

                      <LoginSocialFacebook className="faceboook"
                        appId='3550416728575004'
                        scope="public_profile,email"
                        onResolve={(res) => {

                          let loginWith = res.provider
                          let UserEmail = res.data.email
                          let facebookUserName = res.data.name
                          let googleUserName = res.data.given_name
                          let UserSocialInfo = [loginWith, UserEmail, googleUserName || facebookUserName]
                          dispatch(SocialMediaAction.getSocialMediaUserInfo(UserSocialInfo))
                          // Ctx.getUserinfo(UserSocialInfo)
                        }}
                        onReject={(err) => {
                          console.log(err)
                        }}
                      >
                        <Link to="#" className="button facebook" style={{ marginBottom: "10px" }}>
                          <img src="./assets/img/icons/fb.svg" alt="" />
                          Sign up with Facebook
                        </Link>
                        {/* <FacebookLoginButton /> */}
                      </LoginSocialFacebook>
                      {/* <Link to="#" className="button facebook">
                        <img src="./assets/img/icons/fb.svg" alt="" />
                        Sign up with Facebook
                      </Link> */}
                      {/* <Link to="#" className="button google">
                        <img src="./assets/img/icons/google.svg" alt="" />
                        Sign up with Google
                      </Link> */}

                      <LoginSocialGoogle
                        className="google"
                        client_id='502126642136-in9bha136tk68sodh9sf6im5gfcj3add.apps.googleusercontent.com'
                        scope="profile email"
                        onResolve={(res) => {

                          let loginWith = res.provider
                          let UserEmail = res.data.email
                          let UserName = res.data.given_name
                          let userPicture = res.data.picture

                          let UserSocialInfo = [loginWith, UserEmail, UserName, userPicture]

                          dispatch(SocialMediaAction.getSocialMediaUserInfo(UserSocialInfo))

                        }}
                        onReject={(err) => {
                          console.log(err)
                        }}
                      >
                        <Link to="#" className="button google" style={{ marginBottom: "10px" }}>
                          <img src="./assets/img/icons/google.svg" alt="" />
                          Sign up with Google
                        </Link>
                        {/* <GoogleLoginButton /> */}
                      </LoginSocialGoogle>
                      {/* <Link to="#" className="button apple" >
                        <img src="./assets/img/icons/apple.svg" alt="" />
                        Sign up with Apple ID
                      </Link> */}
                    </div>

                    {/* <!-- divider --> */}
                    <div className="divider">
                      <span>or via email</span>
                    </div>

                    {/* <!-- form --> */}
                    <p style={{ textAlign: 'center', color: 'red' }}>
                      {error && (
                        <>
                          {error}
                          {error !== "User Not Exist" && (
                            <>
                              {" "}
                              <Link style={{ color: "blue" }} to="mailto:hola@bigphotohunt.com">
                                hola@bigphotohunt.com
                              </Link>{" "}
                              for help.
                            </>
                          )}
                        </>
                      )}
                    </p>

                    <div className="form">
                      <form onSubmit={handleSubmit} className="sighIn_form">
                        <div className="form_group">
                          <input
                            type="email"
                            placeholder="Email"
                            name='email'
                            onChange={handleChange}
                            value={values.email}
                            onBlur={handleBlur}
                          />
                          <div className="form_error"></div>
                        </div>
                        {errors.email && touched.email ? (<p className="eerror" >{errors.email} </p>) : null}
                        <div className="form_group">
                          <input
                            type="password"
                            name="password"
                            placeholder="Password"
                            onChange={handleChange}
                            // onChange={(e) => {
                            //   handleChange(e);
                            //   setFieldTouched('password', true);
                            // }}
                            value={values.password}
                            onBlur={handleBlur}
                          />
                          <div className="form_error"></div>
                        </div>
                        {errors.password && touched.password ? (<p className="eerror" >{errors.password} </p>) : null}

                        <div className="buttons">
                          {!spinner ?
                            <button className="btn" type="submit"> Sign In </button>
                            :
                            <button className="btn"  >
                              <span class="loader"></span>
                            </button>}
                        </div>
                      </form>
                    </div>

                    {/* <!-- privacy text --> */}
                    <div className="privacy_text">
                      <p>
                        We will never post to any of your accounts without your
                        permission
                      </p>
                    </div>
                    <span id="applee" data-bs-toggle="modal"
                      data-bs-target="#joinModal"></span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* } */}
    </>
  );
};

export default SignInModal;

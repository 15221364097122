import React from 'react'
import ProfileInfo from '../Components/Profile/ProfileInfo'
import NoPhotoSection from '../Components/Profile/NoPhotoSection'
const ProfileNoPhoto = () => {
    return (
        <>
            {/* <ProfileInfo /> */}

            <NoPhotoSection />
        </>
    )
}

export default ProfileNoPhoto
import React from 'react'
import { Link } from 'react-router-dom'
import { useDispatch } from "react-redux";
import { ModalAction } from "../../Store/Modal-Slice";



const NoPhotoSection = () => {

  let dispatch = useDispatch()
  return (
    <>
    <main className="site-content" id="main">
        {/* <!-- start: Photo Feed --> */}
        <section className="photo-feed-section profile-no-photo">
          <div className="container">
            <div className="row">
              <div className="col-md-12">
                <div className="feed_content">
                  <ul className="heading_tabs" role="tablist">
                    <li
                      className="active"
                      role="presentation"
                      data-bs-toggle="tab"
                      data-bs-target="#profile"
                    >
                      My Photos
                    </li>
                    <li
                      role="presentation"
                      data-bs-toggle="tab"
                      data-bs-target="#upvotes"
                    >
                      Upvotes
                    </li>
                  </ul>

                  <div className="tab-content" id="profileTabContent">
                    <div
                      className="tab-pane fade show active"
                      id="profile"
                      role="tabpanel"
                    >
                      <div className="no_photo_content">
                        <img src="./assets/img/icons/no-photo.svg" alt="" />
                        <h4 className="title">
                          Looks like you don’t have any photos yet
                        </h4>
                        <p>To learn more about posting photo, check out our</p>
                        
                        <div>
                          <button className="btn" onClick={()=>{dispatch(ModalAction.setPhotoUploadModalState(true))}}>
                            Share a new photo
                          </button>
                        </div>
                      </div>
                    </div>

                    <div className="tab-pane fade" id="upvotes" role="tabpanel">
                      <div className="no_photo_content">
                        <img src="./assets/img/icons/no-photo.svg" alt="" />
                        <h4 className="title">
                        Looks like you haven’t given anyone an upvote yet.
                        </h4>
                        <p>Give an upvote to start celebrating your favorite photographers.</p>
                        {/* <Link to="#">guide to successful photo sharing.</Link> */}
                        <div>
                          <Link to="/" className="btn" >
                          Give an upvote
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* <!-- end: Photo Feed --> */}
      </main>
    </>
  )
}

export default NoPhotoSection
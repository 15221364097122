import React from 'react'
import { useDispatch } from "react-redux";
import { ModalAction } from "../../Store/Modal-Slice";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom"


const PopupModal = () => {
    let dispatch = useDispatch()
    let navigate = useNavigate();

    let popupData = useSelector(state => state.Modal.PopupModalMessage)
    let popupHeading = popupData.popupHeading || "You’re Perfect for Us."
    let popupMessage = popupData.popupMessage || "You’re in! Don’t forget to check your email Tuesdays for each week's most upvoted photos."

    let ContinueHandler = () => {
        dispatch(ModalAction.setPopupModalState(false))
        
        if (popupData.photoID && !localStorage.getItem("source")) {
            navigate(`/otherusers-profile?photoid=${popupData.photoID}&userid=${popupData.userID}`);
        }
    }
    return (
        <>
            <div className="backdrop" />

            <div className="modal fade bph-modal show" id="generalLoginModal" tabIndex={-1} aria-labelledby="generalLoginModal" aria-hidden="true"
                style={{ display: 'block' }}
            >
                <div className="modal-dialog modal-dialog-centered modalAnimation">
                    <div className="modal-content">
                        <div className="modal-body">
                            <div className="onboarding_modal login_modal">
                                <div className="modal_inner">
                                    <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" onClick={ContinueHandler} />
                                    {/* logo */}
                                    <div className="modal_logo d-none d-md-inline-flex">
                                        <img src="./assets/img/logo/modal-logo.png" alt />
                                    </div>
                                    {/* content */}
                                    <div className="modal_content">
                                        <div className="modal_title">
                                            <h2 className="title">{popupHeading}</h2>
                                            <p className="subtitle">{popupMessage}</p>
                                            <button className="btn" type="submit" onClick={ContinueHandler}>Continue</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>


        </>
    )
}

export default PopupModal
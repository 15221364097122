import React from 'react'
import { useLocation } from "react-router-dom";
import { useState, useEffect, useRef } from "react";
import axios from 'axios';
import OtherUserSinglePhoto from './OtherUserSinglePhoto';
import UserMorePhotoList from './UserMorePhotoList';
import OtherUserPhotoList from './OtherUserPhotoList';
import { decryptUserData } from '../../utils/encryptedData'



let increment = 0;
const OtherUserPhotoFeed = () => {
  let [SinglePhoto, setSinglePhoto] = useState([])
  let [firstloadPhoto, setfirstloadPhoto] = useState([])
  let [loadPhoto, setloadPhoto] = useState([])
  let [CheckEmptyArry, setCheckEmptyArry] = useState(true)
  let [spinner, setspinner] = useState(false)
  let [MorePhotoState, setMorePhotoState] = useState(true)
  let [OtherPhotoState, setOtherPhotoState] = useState(false)

  let footerr = useRef();

  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  // Get individual query parameters
  let PhotoId = searchParams.get('photoid');
  let UserId = searchParams.get('userid');


  let MorePhotoHndler = () => {
    setMorePhotoState(true)
    setOtherPhotoState(false)
    setCheckEmptyArry(true)
  }

  let OtherPhotoHndler = () => {
    setMorePhotoState(false)
    setOtherPhotoState(true)
    setCheckEmptyArry(true)
  }

  // <---------------- decrypted data ------------->
  const encryptedUserData = localStorage.getItem('Data');
  const decryptedUserData = decryptUserData(encryptedUserData);
  let usertoken = decryptedUserData.token


  // <-------------------------------------------->

  useEffect(() => {

    let singlePhotos = async function () {


      if (!PhotoId) {

        return
      }

      try {
        let payload = {
          "photo_id": PhotoId,
          "userType": "by_user"
        };
        let headers = {
          'Authorization': `Bearer ${usertoken}`,
          'Content-Type': 'application/json'
        }
        let response = await axios.post(`${process.env.REACT_APP_BASE_URL}/photo/get_photo_by_id`, payload, { headers });



        setSinglePhoto(response.data.data[0])

      } catch (error) {
        console.error('Error:', error);

      }


    }
    singlePhotos();
  }, [PhotoId, UserId])


  let apiCall = (e) => {
    if (e[0].isIntersecting) {
      increment = increment + 10;

      let LoadPhotos = async function () {

        setspinner(true)

        // ......................
        try {
          let payload;
          if (MorePhotoState) {
            payload = {
              "user_id": UserId,
              "photo_id": PhotoId,
              "offset": increment
            };
          } else {
            payload = {
              "user_id": UserId,
              "offset": increment
            };
          }


          let headers = {
            'Authorization': `Bearer ${usertoken}`,
            'Content-Type': 'application/json'
          }

          let response;
          if (MorePhotoState) {
            response = await axios.post(`${process.env.REACT_APP_BASE_URL}/photo/get_user_photos`, payload, { headers });

          } else {
            response = await axios.post(`${process.env.REACT_APP_BASE_URL}/photo/get_all_user_photos`, payload, { headers });

          }



          const newPhotos = response.data.data;
          setloadPhoto(prevPhotos => [...prevPhotos, ...newPhotos])

          if (response.data.data.length == 0 || response.data.data.length < 10) {
            setCheckEmptyArry(false)
          }

          setspinner(false)


        } catch (error) {
          console.error('Error:', error);


        }
      }
      LoadPhotos();
    }


  }


  //////////


  ///////////

  useEffect(() => {

    const Observer = new IntersectionObserver(apiCall, {
      root: null,
      threshold: 0

    })

    if (footerr.current) Observer.observe(footerr.current)
    return () => {
      if (footerr.current) Observer.unobserve(footerr.current);
    };
  }, [firstloadPhoto, MorePhotoState])
  ////////////
  useEffect(() => {
    setMorePhotoState(true)
    setOtherPhotoState(false)

  }, [PhotoId, UserId])


  useEffect(() => {
    setfirstloadPhoto([])
    setloadPhoto([])

    let LoadPhotos = async function () {



      // ......................
      setspinner(true)
      try {
        let payload;
        if (MorePhotoState) {
          payload = {
            "user_id": UserId,
            "photo_id": PhotoId,
            "offset": 0
          };
        } else {
          payload = {
            "user_id": UserId,
            "offset": 0
          };
        }


        let headers = {
          'Authorization': `Bearer ${usertoken}`,
          'Content-Type': 'application/json'
        }

        let response;
        if (MorePhotoState) {
          response = await axios.post(`${process.env.REACT_APP_BASE_URL}/photo/get_user_photos`, payload, { headers });

        } else {
          response = await axios.post(`${process.env.REACT_APP_BASE_URL}/photo/get_all_user_photos`, payload, { headers });

        }

      

        setfirstloadPhoto(response.data.data)
        setspinner(false)

        increment = 0

      } catch (error) {
        console.log('Error:', error.response.data.message);
         }
      // .............

    }
    LoadPhotos();
  }, [MorePhotoState,PhotoId])

  let AllLoadPhoto;
  if (increment === 0) {
    AllLoadPhoto = [...firstloadPhoto]
  } else {

    AllLoadPhoto = [...firstloadPhoto, ...loadPhoto]

  }


  let userName;
  if (SinglePhoto && SinglePhoto.name) {
    userName = SinglePhoto.name.split(' ')[0].substring(0, 10);
  }


  return (
    <>
      {/* start: Photo Feed */}
      <section className="photo-feed-section comment-on">
        <div className="container">
          <div className="row">
            <div className="col-md-9 col-lg-8">
              <div className="feed_content">
                {SinglePhoto.length !== 0 && <div className="feed_content_heading d-flex flex-column flex-lg-row align-items-center justify-content-center justify-content-lg-between flex-wrap">
                  <h3 className="title">Tell {userName} What You Think of Their Photo.</h3>
                </div>}
                <div className="feed_items text-center text-md-start">


                  {SinglePhoto.length !== 0 && <OtherUserSinglePhoto

                    img={SinglePhoto.photo_url}
                    postId={SinglePhoto.id}
                    userLocation={SinglePhoto.state}
                    TotalVotes={SinglePhoto.total_votes}
                    voted={SinglePhoto.is_vote}
                    Name={SinglePhoto.name}
                    postedDate={SinglePhoto.photo_posted_date}
                    Userlocation={SinglePhoto.state}
                    UserPhoto={SinglePhoto.user_photo_url}
                    Userid={SinglePhoto.user_id}

                  />}


                  <div className="feed_inner_tabs">
                    <ul className="heading_tabs" role="tablist">
                      <li className={`${MorePhotoState ? "active" : ''}`} role="presentation" data-bs-toggle="tab" data-bs-target="#more" onClick={MorePhotoHndler}>
                        More from {userName}
                      </li>
                      <li className={`${OtherPhotoState ? "active" : ''}`} role="presentation" data-bs-toggle="tab" data-bs-target="#other" onClick={OtherPhotoHndler}>
                        Other users' photos
                      </li>
                    </ul>
                    <div className="tab-content" id="feedInnerTabContent">


                      {MorePhotoState && <div className="" id="more" role="tabpanel" >


                        {AllLoadPhoto.map((data) => (<UserMorePhotoList

                          img={data.photo_url}
                          postId={data.id}
                          key={data.id}
                          TotalVotes={data.total_votes}
                          voted={data.is_vote}
                          Name={data.name}
                          UserPhoto={data.user_photo_url}
                          postedDate={data.photo_posted_date}
                          Userlocation={data.state}
                          Userid={data.user_id}
                        />))
                        }

                      </div>}


                      {OtherPhotoState && <div className="" id="other" role="tabpanel" >


                        {AllLoadPhoto.map((data) => (<OtherUserPhotoList

                          img={data.photo_url}
                          postId={data.id}
                          key={data.id}
                          TotalVotes={data.total_votes}
                          voted={data.is_vote}
                          Name={data.name}
                          UserPhoto={data.user_photo_url}
                          postedDate={data.photo_posted_date}
                          Userlocation={data.state}
                          Userid={data.user_id}

                        />))
                        }

                      </div>}

                      {CheckEmptyArry && spinner && <div style={{ display: "flex", justifyContent: "center" }}>
                        <div className="spinner-border text-secondary" role="status">
                          <span className="sr-only"></span>
                        </div>
                      </div>}

                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      {CheckEmptyArry && AllLoadPhoto.length > 0 && <div ref={footerr}></div>}
      {/* end: Photo Feed */}



    </>
  )
}

export default OtherUserPhotoFeed
import { enc, AES } from 'crypto-js';

const secretKey = 'siddiqui';

export const decryptUserData = (encryptedUserData) => {
  let decryptedUserData = {};

  if (encryptedUserData) {
    const decryptedBytes = AES.decrypt(encryptedUserData, secretKey);
    try {
      decryptedUserData = JSON.parse(decryptedBytes.toString(enc.Utf8));
    } catch (error) {
      console.error('Error parsing decrypted user data:', error);
    }
  }

  return decryptedUserData;
};

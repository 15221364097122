
import React from 'react'
import { Link } from 'react-router-dom'
import { useRef, useState } from 'react';
import { useEffect } from "react";
import axios from "axios";
import Commentslist from './Commentslist';
import { decryptUserData } from '../../utils/encryptedData'



let increment = 0;
const CommentsListContainer = (props) => {
    let [loadcomments, setloadcomments] = useState([])
    let [ShowMorebtn, setShowMorebtn] = useState([])
    let [checkEmptyArr, setcheckEmptyArr] = useState(true)
    let [spinner, setspinner] = useState(false)

    // <---------------- decrypted data ------------->
    const encryptedUserData = localStorage.getItem('Data');
    const decryptedUserData = decryptUserData(encryptedUserData);
    let usertoken = decryptedUserData.token
    // <-------------------------------------------->

    let ViewMoreCommentsHandler = async (event) => {
        event.preventDefault();
        increment = increment + 4;
       


        setspinner(true)
        try {
            const payload = {
                "photoID": props.postid,
                "offSet": increment
            };
            let headers = {
                'Authorization': `Bearer ${usertoken}`,
                'Content-Type': 'application/json'
            }
            const response = await axios.post(`${process.env.REACT_APP_BASE_URL}/comment/list`, payload, { headers });
            
            let newComments = response.data.data.comments
            setloadcomments(prevComments => [...prevComments, ...newComments])
            setspinner(false)

           


        } catch (error) {
            console.error('Error:', error.response.data.message);
           
        }



    }

    useEffect(() => {
        increment = 3;

    }, [props.postid])

    ////////////////////////////////////////
    useEffect(() => {

        let LoadcommentsList = async function () {

         


            setspinner(true)
            // ......................
            try {
                const payload = {
                    "photoID": props.postid,
                    "offSet": 3
                };
                let headers = {
                    'Authorization': `Bearer ${usertoken}`,
                    'Content-Type': 'application/json'
                }
                const response = await axios.post(`${process.env.REACT_APP_BASE_URL}/comment/list`, payload, { headers });
             
                setloadcomments(response.data.data.comments)
                setShowMorebtn(response.data.data.total_comments)
                setspinner(false)

                if (response.data.data.total_comments < 4) {
                    setcheckEmptyArr(false)
                }
              
            } catch (error) {
                console.error('Error:', error.response.data.message);
                
            }


        }
        LoadcommentsList();
    }, [])

    useEffect(() => {
       
        if (loadcomments.length === ShowMorebtn) {
            setcheckEmptyArr(false)
        }
    }, [loadcomments])
   
    return (
        <>
            <div className="comment_lists" >
                <ul>

                    {loadcomments?.map((data) => (
                        <Commentslist
                            commentText={data.comment_text}
                            username={data.username}
                            key={data.id}
                            Ceatedat={data.createdat}
                            userPhoto={data.user_profile_pic}
                        />))
                    }

                    {spinner && <div style={{ display: "flex", width: "33px", height: "30px" }}>
                        <div className="spinner-border text-warning" role="status">
                            <span className="visually-hidden" />
                        </div>

                    </div>}

                </ul>


                {checkEmptyArr && <p className="more_comment">
                    <Link to="#" onClick={ViewMoreCommentsHandler}>
                        <img src="./assets/img/icons/comment.svg" />
                        View More
                    </Link>
                </p>}
            </div>

        </>
    )
}

export default CommentsListContainer